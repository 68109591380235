import {
  IGraph,
  IIncomes,
  IOptionGraph,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import { InputText } from '@components/atomic/inputs/InputText';
import { useContext } from 'react';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { arrayAgeOptions } from '@models/worksiteCreation/utils/functions';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import {
  ACTIVITY,
  HABITATION_ZONE,
} from '@models/worksiteCreation/utils/enums';

interface IRenderFormProps {
  graph: IGraph;
}

function RenderFormGraphGeneral({ graph }: IRenderFormProps) {
  const { t } = useTranslation();
  const { incomesOptionsArray, updateSimulatorData, simulatorData, readOnly } =
    useContext(WorksiteCreationContext);

  let optionsToUse = graph.options ? [...graph.options] : [];
  // Les choix du type de bénéficiaire dépend du type du batiment
  switch (simulatorData['general.habitationZone']) {
    case HABITATION_ZONE.QPV:
      optionsToUse = optionsToUse.filter(
        (option) => option.value === ACTIVITY.SYNDIC
      );
      break;
    case HABITATION_ZONE.CONTRACTED:
      optionsToUse = optionsToUse.filter(
        (option) => option.value === ACTIVITY.SOCIAL_LANDLORD
      );
      break;
    default:
      break;
  }

  const arrayOptions = (graphKey: string) => {
    if (graphKey === 'general.income') {
      return incomesOptionsArray;
    }
    if (graphKey === 'general.age') {
      return arrayAgeOptions(t);
    }
    return graph.options;
  };

  const handleDropdownSelectedValue = (
    graphOption: IOptionGraph[],
    key: string
  ): string | undefined => {
    const selectedValue = Object.values(graphOption).find(
      (elt) => elt.label === key
    );
    return selectedValue ? selectedValue.value : undefined;
  };

  switch (graph.input) {
    case 'input_type_number':
      return (
        <InputText
          dataTestId={`input_type_number_${graph.key}`}
          typeNumber
          placeholder={graph.name}
          id={graph.key}
          name={graph.key}
          valueInitialInput={simulatorData[graph.key] || 0}
          required
          onChange={(e) =>
            updateSimulatorData({ ...simulatorData, [graph.key]: e.toString() })
          }
          disabled={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
      );
    case 'number':
      return (
        <InputText
          dataTestId={`input_number_${graph.key}`}
          typeNumber
          maxNumber={
            graph.key.includes('habitationNumberContracted') ||
            graph.key.includes('habitationNumberQpv')
              ? simulatorData['general.habitationNumber']
              : undefined
          }
          placeholder={
            graph.key === 'general.habitationNumber' ? '' : graph.name
          }
          id={graph.key}
          name={graph.key}
          valueInitialInput={simulatorData[graph.key] || 0}
          required
          onChange={(e) =>
            updateSimulatorData({ ...simulatorData, [graph.key]: Number(e) })
          }
        />
      );
    case 'input_text':
      return (
        <InputText
          dataTestId={`input_text_${graph.key}`}
          placeholder={graph.name}
          id={graph.key}
          name={graph.key}
          required
          valueInitialInput={simulatorData[graph.key] || ''}
          onChange={(e) =>
            updateSimulatorData({ ...simulatorData, [graph.key]: e.toString() })
          }
          disabled={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
      );
    case 'input_date_picker':
      return (
        <DatePicker
          required
          noDefaultDate
          defaultDate={simulatorData[graph.key]}
          onChangeDate={(e) =>
            updateSimulatorData({ ...simulatorData, [graph.key]: e.toString() })
          }
          dataTestId={`date_picker_${graph.key}`}
          // todo disable
        />
      );
    case 'input_dropdown':
      return (
        <InputSelect
          dataTestIdSelect={`input_select_${graph.key}`}
          addClass="w-full"
          defaultSelected={simulatorData[graph.key]}
          placeholder={t('global.choose')}
          dataArrayString={
            graph.options
              ? Object.values(graph.options).map((option) => option.label)
              : []
          }
          onSelect={(e) => {
            updateSimulatorData({
              ...simulatorData,
              [graph.key]: graph.options
                ? handleDropdownSelectedValue(graph.options, e)
                : null,
            });
          }}
          required
          disabled={readOnly}
        />
      );
    case 'choice':
      if (graph.key === 'general.income' || graph.key === 'general.age') {
        const incomes = graph.key === 'general.income';
        return (
          <div
            className={` ${
              incomes ? 'space-y-[1rem]' : 'flex w-full space-x-[.5rem]'
            }`}
            data-test-id={graph.key}
          >
            {arrayOptions(graph.key)?.map((option: IIncomes | IOptionGraph) => {
              const precarityType =
                simulatorData['general.income.precarityType'] || null;
              const selectValue = () => {
                if (!simulatorData[graph.key] && !incomes) return '';
                if (incomes)
                  return precarityType ? precarityType.toString() : '';
                return simulatorData[graph.key]?.toString();
              };

              const isChecked = () => {
                if (!simulatorData[graph.key] && !incomes) return false;
                if (incomes)
                  return Number(precarityType) === Number(option.value);
                return simulatorData[graph.key] === option.value;
              };

              const onSelect = (e: string) => {
                if (incomes) {
                  updateSimulatorData({
                    ...simulatorData,
                    'general.income.precarityType': Number(
                      incomesOptionsArray?.filter(
                        (optionIncome: IIncomes) =>
                          optionIncome.value === Number(e)
                      )[0].value
                    ),
                  });
                } else {
                  updateSimulatorData({
                    ...simulatorData,
                    [graph.key]: e,
                  });
                }
              };

              return (
                <TextWithRadio
                  dataTestId={`input_radio_${graph.key}_${option.value}`}
                  key={option.label}
                  label={
                    incomes ? `${option.title} ${option.label}` : option.label
                  }
                  value={option.value.toString()}
                  addClass="flex-grow"
                  selectValue={selectValue()}
                  isChecked={isChecked()}
                  setSelectedValue={(e) => onSelect(e)}
                  disabled={readOnly}
                />
              );
            })}
          </div>
        );
      }
      return (
        <div className="flex w-full space-x-[.5rem]">
          {optionsToUse.map((option) => (
            <TextWithRadio
              dataTestId={`input_radio_${graph.key}_${option.value}`}
              key={option.label}
              label={option.label}
              value={option.value.toString()}
              addClass="flex-grow"
              selectValue={
                optionsToUse.length === 1
                  ? optionsToUse[0].value
                  : simulatorData[graph.key]?.toString()
              }
              setSelectedValue={(e) =>
                updateSimulatorData({ ...simulatorData, [graph.key]: e })
              }
              isChecked={
                simulatorData[graph.key] === option.value ||
                optionsToUse.length === 1
              }
              disabled={readOnly}
            />
          ))}
        </div>
      );
    default:
      return <div />;
  }
}

export { RenderFormGraphGeneral };
