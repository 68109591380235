import { Card } from '@components/Card';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { IUserType } from '@models/auth/utils/types';
import { getContacts } from '@models/users/apiRequests/userRequests';
import { GlobalContext } from '@context/globalContext';
import { storeContract } from '@models/contractCreation/apiRequests/contractCreationRequests';
import { getPayload } from '@models/contractCreation/utils/payloads';
import { placeholderExample } from '@utils/utils';

function StepReferents() {
  const methods = useForm();

  const contractContext = useContext(ContractCreationContext);
  const { userView } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const placeholders = placeholderExample(t);
  const {
    updateReferents,
    referents,
    changeStep,
    contactList,
    updateContactList,
    contractType,
    updateRelatedContract,
  } = contractContext;

  const getContactList = async () => {
    if (contactList.length < 1) {
      const res = await getContacts();
      updateContactList(res);
    }
  };

  useEffect(() => {
    getContactList();
  }, []);

  const setReferentInfos = (
    key: 'contact_1' | 'contact_2' | 'contact_signataire',
    value: string
  ) => {
    const contact = contactList.find(
      (elt) => `${elt.firstname} ${elt.lastname}` === value
    );

    if (contact) {
      const { id, contact_user_id } = contact;

      updateReferents((prevState) =>
        prevState
          ? {
              ...prevState,
              [key]: { id, contact_user_id },
            }
          : {
              contact_signataire: { id: 0, contact_user_id: 0 },
              contact_1: { id: 0, contact_user_id: 0 },
              [key]: { id, contact_user_id },
            }
      );
    }
  };

  const getName = (user: IUserType) => `${user.firstname} ${user.lastname}`;

  const contactArrayList = useMemo(() => {
    return contactList.map((elt) => getName(elt));
  }, [contactList]);

  const getReferentContact = (
    key: 'contact_1' | 'contact_2' | 'contact_signataire'
  ) => {
    if (!referents) return '';

    const contact = contactList.find(
      (elt) => elt.id === referents[key]?.contact_user_id
    );
    if (!contact) return '';
    return getName(contact);
  };

  const onClickNext = async () => {
    if (
      [ContractTypes.VENTE, ContractTypes.DELEGATION].includes(contractType) &&
      userView
    ) {
      setIsLoading(true);
      const payload = getPayload(contractContext, userView, null);
      const res = await storeContract(payload);

      if (res) {
        updateRelatedContract({
          id: Number(res.id),
          label: String(res.reference),
          contract_type: Number(res.contract_type),
          incentive_type: Number(res.incentive_type),
        });
        changeStep('end');
      }
      setIsLoading(false);
    } else {
      changeStep('next');
    }
  };

  return (
    <div>
      <Card
        title={t('contract.referents')}
        actionButtons={
          <div className="flex gap-3">
            <ButtonOpx
              type="secondary"
              label={`${t('global.back')}`}
              onClick={() => changeStep('back')}
            />
            <ButtonOpx
              type="primary"
              label={
                [ContractTypes.VENTE, ContractTypes.DELEGATION].includes(
                  contractType
                ) && userView
                  ? t('buttons.validate')
                  : t('buttons.next')
              }
              onClick={onClickNext}
              disabled={
                isLoading || // Prevent clicks during loading
                !referents ||
                referents.contact_signataire?.id < 1 ||
                referents.contact_1?.id < 1 ||
                referents?.contact_signataire === null ||
                referents?.contact_1 === null
              }
              dataTestId="button_next"
              isLoading={isLoading}
            />
          </div>
        }
      >
        <div>
          <FormProvider {...methods}>
            <p className="font-medium mb-[1rem]">
              {t('contract.contact_signataire')}
            </p>
            <div className="flex gap-3 w-full">
              <InputSelect
                placeholder={placeholders.SELECT}
                defaultSelected={placeholders.SELECT}
                label={String(t('contract.signatory'))}
                addClass="w-1/2"
                dataArrayString={contactArrayList}
                onSelect={(e) =>
                  setReferentInfos('contact_signataire', String(e))
                }
                required
                dataTestIdSelect="select_signataire"
                dataTestIdOptions="signataire"
                valueInput={getReferentContact('contact_signataire')}
              />
              <div className="w-1/2" />
            </div>

            <p className="font-medium my-[1rem]">
              {t('contract.contact_referent')}
            </p>
            <div className="flex gap-3 w-full mt-3">
              <InputSelect
                placeholder={placeholders.SELECT}
                defaultSelected={placeholders.SELECT}
                label={`${t('contract.contact_primary')}`}
                addClass="w-1/2"
                dataArrayString={contactArrayList}
                onSelect={(e) => setReferentInfos('contact_1', String(e))}
                required
                valueInput={getReferentContact('contact_1')}
                dataTestIdOptions="referent_primary"
                dataTestIdSelect="select_referent_primary"
              />
              <InputSelect
                placeholder={placeholders.SELECT}
                defaultSelected={placeholders.SELECT}
                label={`${t('contract.contact_secondary')}`}
                addClass="w-1/2"
                dataArrayString={contactArrayList}
                onChangeValue={(e) => setReferentInfos('contact_2', String(e))}
                valueInput={getReferentContact('contact_2')}
                dataTestIdOptions="referent_secondary"
                dataTestIdSelect="select_referent_secondary"
              />
            </div>
          </FormProvider>
        </div>
      </Card>
    </div>
  );
}

export { StepReferents };
