import { TFunction } from 'i18next';
import { IKeyJSXElementType } from '@models/worksites/utils/worksitesTypes';
import { dateToDDMMYYY } from '@utils/format';
import { totalBonus } from '@models/worksites/utils/utils';
import { NavigateFunction } from 'react-router-dom';
import { IUserType } from '@models/auth/utils/types';
import { isAmo, isInstaller } from '@utils/roles';
import { InputTypes } from '@utils/utils';
import { removalToDisplay } from '@models/worksiteCreation/utils/functions';
import { AddressType, OperationTypeEnum } from '@utils/enums';
import {
  CONTRACTS_ROUTES_WITH_ID,
  PARTNERS_ROUTES_WITH_ID,
} from '@utils/routesUrls';
import { IGlobalEnumType, IKeyStringType } from '../../../types/globalTypes';
import { IWorksiteDetails } from './worksitesTypes';
import { numFormatSpace, objectToStringArray } from '../../../utils/functions';

export const WORKSITES_LIST_TABS = (
  t: TFunction,
  user: IUserType | null
): IKeyStringType => {
  const showSimulations = isInstaller(user) || isAmo(user);

  const list = {
    ALL: t('worksites.all'),
    PROCESS: t('worksites.toprocess'),
    SIMULATIONS: t('worksites.simulations'),
    /* PROGRESS: t('worksites.inprogress'),
    DONE: t('worksites.done'), */
  };

  if (!showSimulations) {
    const { SIMULATIONS, ...updatedList } = list;
    return updatedList;
  }

  return list;
};

export const WORK_SITE_DETAILS_TABS = (t: TFunction): IKeyStringType => {
  return {
    OP: t('worksites.operations'),
    DOCS: t('worksites.documents.title'),
    DETAILS: t('worksites.worksitedetails'),
  };
};

export const beneficiaryData = (
  worksite: IWorksiteDetails,
  t: TFunction,
  isEditMode: boolean,
  iconsList: IKeyJSXElementType,
  globalEnum: IGlobalEnumType,
  precarity?: string
) => {
  const { beneficiary, fiscalDeclarations } = worksite;

  // beneficiary data is saved for addresstype=correspondance on back
  const beneficiaryAddress =
    beneficiary?.addresses?.data.filter(
      (a) => a.address_type === AddressType.CORRESPONDANCE
    ) || [];

  const isB2b = worksite.operation_type === OperationTypeEnum.B2B;
  const occupationType = globalEnum.occupation_type;

  delete occupationType[2];

  const needFiscalData =
    worksite.operation_type !== OperationTypeEnum.B2B &&
    precarity !== globalEnum.precarity_type[4];

  return {
    top: [
      {
        name: beneficiary.company_name
          ? 'beneficiary.company_name'
          : 'beneficiary.firstname',
        inputType: InputTypes.TEXT,
        initialValue: beneficiary.company_name || beneficiary.firstname,
        label: beneficiary.company_name
          ? `${t('infos.company_name')}`
          : `${t('worksites.beneficiary.first_name')}`,
        isEditable: isEditMode,
        icon: iconsList.person,
        required: true,
      },
      beneficiary.lastname
        ? {
            name: 'beneficiary.lastname',
            inputType: InputTypes.TEXT,
            initialValue: beneficiary.lastname,
            label: `${t('worksites.beneficiary.last_name')}`,
            isEditable: isEditMode,
            required: true,
          }
        : { empty: true },
      {
        name: 'beneficiary.email',
        inputType: InputTypes.EMAIL,
        label: t('worksites.beneficiary.mail'),
        initialValue: beneficiary.email,
        isEditable: isEditMode,
        required: true,
      },
      isB2b && beneficiary.firstname
        ? {
            name: 'beneficiary.firstname',
            inputType: InputTypes.TEXT,
            label: t('worksites.beneficiary.first_name'),
            initialValue: beneficiary.firstname,
            isEditable: isEditMode,
          }
        : { empty: true },
      {
        name: 'beneficiary.mobile_phone',
        inputType: InputTypes.PHONE,
        label: t('worksites.beneficiary.phone'),
        initialValue: `${beneficiary.mobile_phone}`,
        isEditable: isEditMode,
        required: true,
      },
      isB2b && beneficiary.signatory_function
        ? {
            name: 'beneficiary.signatory_function',
            inputType: InputTypes.TEXT,
            label: t('worksites.beneficiary.signatory_function'),
            initialValue: beneficiary.signatory_function,
            isEditable: isEditMode,
          }
        : { empty: true },
      {
        name: 'beneficiary.address.address',
        inputType: InputTypes.ADDRESS,
        label: t('worksites.beneficiary.address'),
        initialValue: `${
          beneficiaryAddress.length > 0 ? beneficiaryAddress[0].address : ''
        }`,
        isEditable: isEditMode,
        icon: iconsList.position,
        required: true,
      },
      {
        name: 'beneficiary.address.city',
        inputType: InputTypes.ADDRESS,
        label: t('worksites.beneficiary.city'),
        initialValue: `${
          beneficiaryAddress.length > 0 ? beneficiaryAddress[0].city : ''
        }`,
        isEditable: isEditMode,
        required: true,
      },
      {
        name: 'beneficiary.address.postal_code',
        inputType: InputTypes.ADDRESS,
        label: t('worksites.beneficiary.zip_code'),
        initialValue: `${
          beneficiaryAddress.length > 0 ? beneficiaryAddress[0].postal_code : ''
        }`,
        isEditable: isEditMode,
        required: true,
      },
      needFiscalData
        ? {
            name: 'beneficiary.fiscal_number',
            inputType: InputTypes.TEXT,
            label: t('worksites.beneficiary.fiscality_number'),
            initialValue:
              fiscalDeclarations && fiscalDeclarations[0]
                ? fiscalDeclarations[0].fiscal_number
                : '',
            icon: iconsList.file,
            isEditable: isEditMode,
            maxLength: 13,
            notRequired: true,
          }
        : { empty: true },

      needFiscalData
        ? {
            name: 'beneficiary.fiscal_reference',
            inputType: InputTypes.TEXT,
            label: t(
              'worksite_creation.create_worksite.tax_household.notice_reference'
            ),
            initialValue:
              fiscalDeclarations && fiscalDeclarations[0]
                ? fiscalDeclarations[0].fiscal_reference
                : '',
            isEditable: isEditMode,
            maxLength: 14,
            notRequired: true,
          }
        : { empty: true },
    ],

    bottom: [
      {
        name: 'persons',
        label: t('worksites.housing.household_people'),
        inputType: InputTypes.NUMBER,
        initialValue: worksite.housing.persons,
        icon: iconsList.beneficiary,
        isEditable: isEditMode,
        required: true,
      },
      {
        name: 'incomes',
        label: t('worksites.housing.household_incomes'),
        inputType: InputTypes.SELECT,
        initialValue: worksite.housing
          ? globalEnum.precarity_type[worksite.precarity_type]
          : '-',
        icon: iconsList.briefcase,
        isEditable: isEditMode,
        options: objectToStringArray(globalEnum.precarity_type),
      },
      {
        name: 'client',
        label: t('worksites.housing.client_type'),
        initialValue: worksite.housing
          ? globalEnum.occupation_type[worksite.housing.occupation_type]
          : '-',
        inputType: InputTypes.SELECT,
        isEditable: isEditMode,
        options: objectToStringArray(occupationType),
      },
    ],
  };
};

export const housingDatas = (
  t: TFunction,
  iconsList: IKeyJSXElementType,
  globalEnum: IGlobalEnumType,
  worksite: IWorksiteDetails,
  isEditMode: boolean
) => {
  const withReplacedEnergy = () => {
    if (!worksite.payload) return false;

    const payload = JSON.parse(worksite.payload);

    return payload.operations.some(
      (elt: { [x: string]: string | number }) => elt['operation.replacedEnergy']
    );
  };

  const worksiteHousing = worksite.housing;

  const { housing_type, age_type, replaced_energy_type } = globalEnum;

  const housingSurfaceInEditMode =
    worksiteHousing.surface === null || worksiteHousing.surface === undefined
      ? undefined
      : worksiteHousing.surface;

  const housingSurfaceNotEditMode =
    worksiteHousing.surface === null || worksiteHousing.surface === undefined
      ? '- m²'
      : `${worksiteHousing.surface} m²`;

  const inputData = [
    {
      name: 'housing',
      inputType: InputTypes.SELECT,
      label: t('worksites.housing.housing_type'),
      options: objectToStringArray(housing_type).filter(
        (elt) => elt !== 'Autre'
      ),
      initialValue: housing_type[worksiteHousing.housing_type],
      isEditable: isEditMode,
      icon: iconsList.housing,
    },
    { empty: true },
    {
      name: 'age',
      inputType: InputTypes.SELECT,
      label: t('worksites.housing.housing_age'),
      options: objectToStringArray(age_type).filter((elt) => elt !== 'Autre'),
      initialValue: age_type[worksiteHousing.age_type],
      isEditable: isEditMode,
    },
    {
      name: 'surface',
      inputType: InputTypes.NUMBER,
      label: t('worksites.housing.surface_area'),
      initialValue: isEditMode
        ? housingSurfaceInEditMode
        : housingSurfaceNotEditMode,
      isEditable: isEditMode,
      notRequired: false,
    },
  ];

  const removal = worksite.worksites_operations.map(
    (operation) => operation.replaced_energy
  );

  if (removal.every((el) => el !== null && el !== 'none')) {
    inputData.push({
      name: 'removal',
      inputType: InputTypes.SELECT,
      label: t('worksites.housing.removal'),
      isEditable: isEditMode && withReplacedEnergy(),
      options: objectToStringArray(replaced_energy_type),
      initialValue: removalToDisplay(worksite.worksites_operations),
    });
  }

  if (worksite.operation_type === OperationTypeEnum.B2B)
    return inputData.filter((input, index) => [2, 4].includes(index));
  return inputData;
};

export const operationsDatas = (
  t: TFunction,
  worksiteDetails: IWorksiteDetails,
  iconsList: IKeyJSXElementType,
  navigate: NavigateFunction,
  isInstall: boolean
) => {
  const rows = [];

  const totalVolume = worksiteDetails.worksites_operations.reduce(
    (sum, obj) =>
      sum + Number(obj.kwhc_classique || 0) + Number(obj.kwhc_precaire || 0),
    0
  );

  rows.push([
    {
      title: t('worksite_creation.steps.worksite_creation'),
      value: dateToDDMMYYY(worksiteDetails.dates?.creation),
      icon: iconsList.info,
    },
    {
      title: t('worksites.total_bonus'),
      value: `${numFormatSpace(
        totalBonus(worksiteDetails.worksites_operations)
      )} €`,
    },
    {
      title: t('worksites.total_volume'),
      value: `${numFormatSpace(totalVolume)} kWhc`,
    },
    {
      title: 'RAI',
      value: worksiteDetails.convention.rai.company_name,
    },
  ]);

  const { worksites_operations: worksitesOperations } = worksiteDetails;

  worksitesOperations.forEach((wo, i) => {
    rows.push([
      {
        title: `${t('worksites.operation.title')} ${i + 1}`,
        value: wo.operation.description,
        icon: i + 1,
      },
      wo.subcontractor
        ? {
            title: t('partners.subcontractors.name'),
            value: wo.subcontractor.company_name,
            onClick: isInstall
              ? () =>
                  navigate(
                    PARTNERS_ROUTES_WITH_ID(Number(wo.subcontractor?.id))
                      .PARTNER_VIEW
                  )
              : undefined,
          }
        : { empty: true },
      {
        title: t('worksites.operation.total_cost'),
        value: `${numFormatSpace(wo.total_cost)} €`,
      },

      wo.surface
        ? {
            title: t('worksites.operation.isolated_area'),
            value: `${wo.surface} m2`,
          }
        : { empty: true },
      {
        name: 'projected_end',
        title: t('worksites.projected_end_date'),
        value: wo.projected_end,
        isEditable: true,
        type: 'projectedEnd',
        id: wo.id,
        worksiteId: worksiteDetails.id,
        defaultDate: wo.projected_end,
      },
      {
        name: 'worksite_operation_id',
        title: '',
        value: [],
        isEditable: true,
        type: 'worksite_operation_id',
        id: wo.id,
        code: wo.operation.code,
      },
    ]);
  });
  return { status: undefined, rows };
};
export const partnerOrInstallerDatas = (
  t: TFunction,
  worksiteDetails: IWorksiteDetails,
  roleUser: string,
  ROLES: IKeyStringType,
  iconsList: IKeyJSXElementType,
  navigate?: NavigateFunction
) => {
  const contractLink = worksiteDetails.amo
    ? CONTRACTS_ROUTES_WITH_ID(worksiteDetails.convention.id)
        .CONVENTION_BENEFICIARY_VIEW
    : CONTRACTS_ROUTES_WITH_ID(worksiteDetails.convention.id)
        .CONVENTION_INSTALLER_VIEW;

  const referent = worksiteDetails.worksite_partner.contact;
  const datas =
    roleUser === ROLES.PRODUCTION // if n, display n+1
      ? {
          general: worksiteDetails.entity_to,
          convention: worksiteDetails.convention.referent,
        }
      : {
          general: worksiteDetails.installer,
          convention: worksiteDetails.convention.contactInstaller[0],
        };
  return {
    status: undefined,
    rows: [
      [
        {
          title: t('worksites.partner.social_reason'),
          value: datas.general.company_name,
          icon: iconsList.infos,
        },
        {
          title: t('worksites.partner.company_register'),
          value: datas.general.siret,
        },
      ],
      [
        {
          title: t('worksites.partner.referent_contact'),
          value: referent ? `${referent.firstname} ${referent.lastname}` : '-',
          icon: iconsList.contact,
        },
        {
          title: t('worksites.partner.function'),
          value: referent ? referent.function : '-',
        },
        {
          title: t('worksites.beneficiary.phone'),
          value: referent ? referent.phone_number : '-',
        },
        {
          title: t('worksites.beneficiary.mail'),
          value: referent ? referent.email : '-',
        },
      ],
      [
        {
          title: worksiteDetails.convention.reference,
          value: `${worksiteDetails.convention.internal_reference} - ${
            roleUser === ROLES.PRODUCTION
              ? worksiteDetails.installer.company_name
              : worksiteDetails.entity_to.company_name
          }`,
          onClick: () => navigate && navigate(contractLink),
          icon: iconsList.contract,
          noWrap: true,
        },

        {
          title: t('convention.validity_dates'),
          value: t('global.from_to', {
            start: dateToDDMMYYY(worksiteDetails.convention.start_date),
            end: dateToDDMMYYY(worksiteDetails.convention.end_date),
          }),
          icon: iconsList.contract,
          colorIcon: 'white',
          noWrap: true,
        },
      ],
    ],
  };
};
