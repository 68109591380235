import {
  IContractOperationPrice,
  PriceType,
} from '@models/contractCreation/utils/contractCreationTypes';
import { InputText } from '@components/atomic/inputs/InputText';
import {
  OperationPriceTypes,
  PRICE_TYPE,
} from '@models/contractCreation/utils/enums';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { formatPriceWithoutRounding } from '@utils/format';
import { useTranslation } from 'react-i18next';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import InformationWithLabel from '@models/contractCreation/components/steps/operationPrices/InformationWithLabel';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { INCENTIVE_TYPE } from '@models/worksiteCreation/utils/enums';
import { useFormContext } from 'react-hook-form';
import { handleMinimumAmountPricesErrors } from '@models/contractCreation/utils/functions';

interface OperationPriceInputsProps {
  operationPriceType: number;
  action: 'add' | 'delete' | 'edit';
  priceValue: IContractOperationPrice | undefined;
  operationPrice?: IContractOperationPrice;
  setPriceValue?: Dispatch<SetStateAction<IContractOperationPrice | undefined>>;
  isLoading: boolean;
  isConvention: boolean;
  isCdp?: boolean;
  fixCdp?: boolean;
  selectedIds: number[];
  operations: IOperationType[];
  isMinimumAmount?: boolean;
  incentive: 'direct' | 'indirect';
  differentIncentivePrice?: boolean;
}

function OperationPriceInputs({
  operationPriceType,
  action,
  priceValue,
  setPriceValue,
  isLoading,
  isConvention,
  isCdp,
  fixCdp,
  operationPrice,
  selectedIds,
  operations,
  isMinimumAmount,
  incentive,
  differentIncentivePrice,
}: OperationPriceInputsProps) {
  const { t } = useTranslation();

  const methods = useFormContext();
  const { incentiveType } = useContext(ContractCreationContext);

  const getPriceKey = (type: PriceType): keyof IContractOperationPrice => {
    if (isMinimumAmount) {
      return `${incentive}_beneficiary_minimum_amount_${
        isCdp ? 'cdp_' : ''
      }${type}`;
    }

    if (isCdp) {
      return fixCdp
        ? `${incentive}_pf_cdp_${type}`
        : `${incentive}_pu_cdp_${type}`;
    }

    if (
      !isConvention &&
      (type === PRICE_TYPE.CLASSIQUE || type === PRICE_TYPE.PRECAIRE)
    ) {
      return `prix_unitaire_${type}`;
    }

    return `${incentive}_pu_${type}`;
  };

  const updatePrice = (value: number, type: PriceType) => {
    const priceKey = getPriceKey(type);
    if (priceValue && action !== 'delete' && setPriceValue) {
      let newValue: IContractOperationPrice = { ...priceValue };

      newValue = { ...newValue, [priceKey]: value };

      if (!differentIncentivePrice && incentiveType === INCENTIVE_TYPE.MIXED) {
        const otherPriceKey = priceKey.startsWith('indirect')
          ? (priceKey.replace('indirect', 'direct') as PriceType)
          : (priceKey.replace('direct', 'indirect') as PriceType);

        newValue = { ...newValue, [otherPriceKey]: value };
      }

      setPriceValue(newValue);
    }
  };

  const mandatoryKeys = useMemo(() => {
    // type de prix obligatoires selon le operation type choisi
    let keys: PriceType[] = [PRICE_TYPE.UNIQUE];

    if (operationPriceType !== OperationPriceTypes.UNIQUE) {
      keys = [PRICE_TYPE.PRECAIRE, PRICE_TYPE.CLASSIQUE];

      if (operationPriceType === OperationPriceTypes.INCOMES) {
        keys.push(PRICE_TYPE.MODESTE, PRICE_TYPE.INTERMEDIAIRE);
      }
    }

    return keys;
  }, [operationPriceType]);

  const disabled = useMemo(() => {
    return isLoading || action === 'delete' || selectedIds.length < 1;
  }, [action, selectedIds, isLoading]);

  const getInputValue = (type: PriceType): string => {
    const element = action !== 'delete' ? priceValue : operationPrice;

    if (!element || !element[getPriceKey(type)]) return '';

    if (action !== 'delete') return String(element[getPriceKey(type)]);
    return formatPriceWithoutRounding(String(element[getPriceKey(type)]));
  };

  const haveBar = operations
    .filter((op) => selectedIds.includes(op.id))
    .some((op) => op.code.toLowerCase().includes('bar-'));

  const dontShowIncentive = !!(isMinimumAmount && isCdp);

  useEffect(() => {
    if (priceValue && isMinimumAmount) {
      handleMinimumAmountPricesErrors(
        priceValue,
        mandatoryKeys,
        action,
        incentive,
        isCdp,
        fixCdp,
        methods,
        t
      );
    }
  }, [priceValue, fixCdp]);

  useEffect(() => {
    if (
      priceValue &&
      setPriceValue &&
      !differentIncentivePrice &&
      incentiveType === INCENTIVE_TYPE.MIXED
    ) {
      Object.keys(priceValue).forEach((key) => {
        const isIndirect = key.startsWith('indirect');

        if (isIndirect) {
          const directKey = key.replace(
            'indirect',
            'direct'
          ) as keyof IContractOperationPrice;
          setPriceValue({ ...priceValue, [key]: priceValue[directKey] });
        }
      });
    }
  }, [differentIncentivePrice]);

  const onePriceInput = (type: PriceType) => {
    if (!isConvention && !haveBar && type === PRICE_TYPE.PRECAIRE)
      return <div />;

    return (
      <div className="w-full">
        {action !== 'delete' ? (
          <InputText
            id={`${action}_${getPriceKey(type)}`}
            name={`${action}_${getPriceKey(type)}`}
            dataTestId={`${action}_${getPriceKey(type)}`}
            placeholder="50"
            label={`${t(`contract.${fixCdp ? 'pf' : 'pu'}_${type}`)}`}
            addClass={isConvention ? 'mb-2' : ''}
            typeNumber
            onChange={(value) =>
              updatePrice(parseFloat(String(value).replace(',', '.')), type)
            }
            disabled={disabled}
            addClassToInput={disabled ? 'bg-backgroundBody' : ''}
            required
          />
        ) : (
          <InformationWithLabel
            label={`${t(`contract.${fixCdp ? 'pf' : 'pu'}_${type}`)}`}
            information={getInputValue(type)}
            withMarginBottom={isConvention}
          />
        )}
      </div>
    );
  };

  const hidePrecaireInput = !isConvention && !haveBar;

  return (
    <div className="w-full">
      {incentiveType === INCENTIVE_TYPE.MIXED &&
        differentIncentivePrice &&
        !dontShowIncentive && (
          <div>
            {t(
              `worksite_creation.form_modal_before_simulation.${incentive}_incentive`
            )}
          </div>
        )}

      <div className="flex justify-between items-center w-full">
        <div className="w-full">
          {isMinimumAmount && (
            <div className="text-textGrey text-sm  font-[500]">
              {isCdp
                ? t('convention.minimum_price_cdp')
                : t('convention.minimum_price')}
            </div>
          )}
          {action === 'delete' &&
          !isConvention &&
          operationPrice?.operation_id &&
          !operationPrice.label?.includes('BAR-') ? (
            <div>{onePriceInput(PRICE_TYPE.CLASSIQUE)}</div>
          ) : (
            <div className="flex w-full gap-3 mt-2">
              {operationPriceType !== OperationPriceTypes.UNIQUE &&
                onePriceInput(PRICE_TYPE.CLASSIQUE)}

              {operationPriceType === OperationPriceTypes.INCOMES &&
                onePriceInput(PRICE_TYPE.INTERMEDIAIRE)}

              {operationPriceType === OperationPriceTypes.INCOMES &&
                onePriceInput(PRICE_TYPE.MODESTE)}

              {operationPriceType !== OperationPriceTypes.UNIQUE &&
                !hidePrecaireInput &&
                onePriceInput(PRICE_TYPE.PRECAIRE)}

              {operationPriceType === OperationPriceTypes.UNIQUE &&
                onePriceInput(PRICE_TYPE.UNIQUE)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

OperationPriceInputs.defaultProps = {
  isCdp: false,
  fixCdp: false,
  isMinimumAmount: false,
  setPriceValue: undefined,
  operationPrice: undefined,
  differentIncentivePrice: false,
};

export { OperationPriceInputs };
