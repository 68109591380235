/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useContext, useMemo } from 'react';
import { v4 } from 'uuid';

import { AuthContext } from '@context/authContext';

import { IInfosLine, IStatus } from 'types/globalTypes';
import { GlobalContext } from '@context/globalContext';
import { green, red } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { useTranslation } from 'react-i18next';

interface IFormInfoUserProps {
  classRow: string;
  renderInputInformation: (col: IInfosLine) => JSX.Element;
  isEditMode: boolean;
  data: {
    status: boolean | string | IStatus | undefined | number;
    rows: IInfosLine[][];
  };
  userIsActive: boolean;
}

function FormInfosUser({
  classRow,
  renderInputInformation,
  isEditMode,
  data,
  userIsActive,
}: IFormInfoUserProps) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { globalEnum } = useContext(GlobalContext);

  const authUserIsAdmin = useMemo(() => {
    return String(user?.role_name).indexOf('admin') !== -1;
  }, [user]);

  const identityStatus =
    data.rows[0] && data.rows[0][3]?.value
      ? `(${globalEnum.identity_status[data.rows[0][3].value]})`
      : '';

  const infoUserRead = (
    <div
      style={{
        minHeight: 100,
        marginLeft: isEditMode ? 20 : 15,
      }}
      className="flex flex-col items-start flex-start"
    >
      <p className="mb-4">
        <span className="font-medium text-[1.25rem]">
          {`${data.rows[0][1].value || ''} ${data.rows[0][2].value || ''}`}{' '}
        </span>
        <span className="text-">{identityStatus}</span>
      </p>
      <p>{data.rows[0][4].value || ''}</p>
      <p>{data.rows[0][5].value || ''}</p>
      <Tag
        color={userIsActive ? green : red}
        label={userIsActive ? t('settings.active') : t('settings.inactive')}
        addClass="mt-2"
      />
    </div>
  );
  return (
    <div className={`${classRow} grid grid-cols-[60%_40%] -mt-8`}>
      <div className={`pr-4 ${!isEditMode || !authUserIsAdmin ? 'flex' : ''}`}>
        {/* image */}
        {renderInputInformation(data.rows[0][0])}
        {isEditMode ? (
          <>
            {authUserIsAdmin ? (
              <div className="grid grid-cols-2 gap-2">
                {data.rows[0].slice(1, 5).map((col: IInfosLine) => (
                  <Fragment key={v4()}>{renderInputInformation(col)}</Fragment>
                ))}
              </div>
            ) : (
              infoUserRead
            )}
          </>
        ) : (
          infoUserRead
        )}
      </div>
      <div className="border border-solid border-transparent border-l-borderGrey pl-6 flex flex-col justify-start space-y-4">
        {data.rows[0].slice(5).map((col: IInfosLine) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`input-info-${col.name}`}>
            {renderInputInformation(col)}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export { FormInfosUser };
