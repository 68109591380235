import { useMemo, useState, useContext, useEffect } from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AuthContext } from '@context/authContext';
import { HeaderContext } from '@context/headerContext';

import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { TabSubcontractorInfos } from '@models/partners/components/subcontractors/TabSubcontractorInfos';
import { TabSubContractorsDocs } from '@models/partners/components/subcontractors/TabSubContractorsDocs';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

import { getSubcontractorInfos } from '@models/partners/apiRequests/subcontractorsRequest';
import {
  IPartnerSubmenu,
  IPartnerInfosGenerales,
} from '@models/partners/utils/types/partnersType';
import {
  DEFAULT_GENERAL_INFOS,
  initialSubcontractorUserInfo,
} from '@models/partners/utils/partnersConstants';
import { ISubcontractorUserType } from '@models/auth/utils/types';
import { Tag } from '@components/atomic/Tag';
import { darkBlue } from '@assets/color';
// import { IConventionType } from '@models/conventions/utils/conventionTypes';

interface ISheetSubcontractorProps {
  loadingTabs: boolean;
  partnersSubMenu: IPartnerSubmenu[];
  installerId: number | null;
}
// NOTE: Fiche Sous-traitants
function SheetSubcontractor({
  loadingTabs,
  partnersSubMenu,
  installerId,
}: ISheetSubcontractorProps): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { updateTitleHeader, updateDisplayBackButtonHeader, updateTagHeader } =
    useContext(HeaderContext);

  const [subcontractor, setSubContractor] = useState<IPartnerInfosGenerales>(
    DEFAULT_GENERAL_INFOS.info_generales
  );
  // const [conventions, setConventions] = useState<IConventionType[]>([]);

  const [userDetails, setUserDetails] = useState<ISubcontractorUserType>(
    initialSubcontractorUserInfo
  );

  const [sheetContent, setSheetContent] = useState<string>('informations');
  const [loading, setLoading] = useState<boolean>(false);

  // A SUPPRIMER APRES LA FIN DES TESTS
  const forceDisplayForTests = true;

  const getData = async () => {
    setLoading(true);

    const resSubContractorInfos = await getSubcontractorInfos(
      Number(installerId),
      Number(id)
    );

    if (resSubContractorInfos) {
      setSubContractor(resSubContractorInfos.subcontractorInfo);
      // setConventions(resSubContractorInfos.conventionInfo);
      setUserDetails(resSubContractorInfos.userInfo);
      updateTitleHeader(
        resSubContractorInfos.subcontractorInfo.company_name || ''
      );
      updateTagHeader(
        <Tag color={darkBlue} label={t('partners.subcontractors.name')} />
      );
    }

    setLoading(false);
  };

  const canManageSubcontractors = useMemo(
    () =>
      (user &&
        user?.permissions_names.includes('gerer-soustraitant') &&
        ['admin-admin', 'installateur-admin'].includes(user?.role_name)) ||
      forceDisplayForTests,
    [user]
  );

  useEffect(() => {
    updateDisplayBackButtonHeader(true);
    updateTitleHeader('loading');
    getData();
  }, [id]);

  return canManageSubcontractors || forceDisplayForTests ? (
    <>
      <div className="tabs_wrapper mt-6 flex">
        {!loadingTabs ? (
          <>
            {partnersSubMenu
              .filter(
                (submenu: IPartnerSubmenu) =>
                  submenu.submenuLabel.toLowerCase().includes('informations') ||
                  submenu.submenuLabel.toLowerCase().includes('documents')
              )
              .map((tab: IPartnerSubmenu) => (
                <ButtonOpx
                  key={v4()}
                  onClick={() =>
                    setSheetContent(tab.submenuLabel.toLowerCase() || '')
                  }
                  label={tab.submenuLabel}
                  type="tab"
                  addClass="mr-3"
                  active={sheetContent === tab.submenuLabel.toLowerCase()}
                  dataTestId="button_sub_header"
                />
              ))}
          </>
        ) : (
          [...Array(3)].map(() => (
            <LoaderSkeleton
              key={v4()}
              height="2.75rem"
              addClass="mr-3 w-[7.5rem]"
            />
          ))
        )}
      </div>
      <div className="tabs_content">
        {sheetContent === 'documents' ? (
          subcontractor.linked_files_subcontractor && (
            <TabSubContractorsDocs
              subcontractorDocs={subcontractor.linked_files_subcontractor}
              // conventions={conventions}
              refetch={getData}
            />
          )
        ) : (
          <TabSubcontractorInfos
            canEdit={canManageSubcontractors}
            subcontractor={subcontractor}
            userDetails={userDetails}
            loading={loading}
            refetch={getData}
          />
        )}
      </div>
    </>
  ) : (
    <div className="py-10 w-full items-center justify-center text-danger">
      <p>{t('global.no_permission')}</p>
    </div>
  );
}

export { SheetSubcontractor };
