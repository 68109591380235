import { DEFAULT_BENEFICIARY } from '@models/beneficiaries/utils/beneficiariesConstants';
import {
  IMaterial,
  IPayloadMaterials,
  IWorksiteOperation,
} from '@models/worksiteCreation/utils/types/worksitesType';

const initialWorksite = {
  address: {
    additional_address: null,
    address: '',
    address_type: 0,
    city: '',
    country: '',
    created_at: '',
    deleted_at: null,
    elevation: null,
    id: 0,
    latitude: '',
    longitude: '',
    postal_code: '',
    selected: false,
    updated_at: '',
    worksite_id: 0,
  },
  beneficiary: DEFAULT_BENEFICIARY,
  fiscality: {
    id: 0,
    is_indivision: false,
    nb_tax_households: 0,
    people_number: 0,
    tax_revenue: 0,
  },
  linkedFiles: [],
  worksites_operations: [
    {
      cdp_amount: 0,
      cee_amount: 0,
      id: 0,
      kwhc: 0,
      mpr_amount: 0,
      status: 0,
      system_type: 0,
      total_cost: 0,
      linkedFiles: [],
      operation: {
        id: 0,
        code: '',
        description: '',
        can_have_material: false,
        totalCost: 0,
        surface: '',
      },
    },
  ],
  convention_id: 0,
  id: 0,
  internal_reference: '',
  operation_type: 0,
  precarity_type: 0,
  reference: '',
  status: 0,
  total_cost: 0,
  updated_at: '',
  payload: '',
  incentive_type: 0,
  offer_expired_at: '',
};
const initialSubcontractor = {
  id_partenaire: 0,
  company_name: '',
  siret: '',
  status: '',
  convention_active: 0,
  convention_inactive: 0,
  showButtonContrat: false,
  entity_type: null,
};

const initialMaterial: IMaterial = {
  id: 0,
  material_id: 0,
  name: '',
  caracteristics: {
    mark: {
      name: '',
    },
    reference: {
      name: '',
    },
  },
  picture_url: '',
  technical_document_url: '',
  created_by: 0,
  created_at: 0,
  updated_at: 0,
};

const materialInitialState = {
  materials: [initialMaterial],
  productSearchValue: '',
  markSearchValue: '',
  productPage: 1,
  lasProductPage: 2,
  isSearching: false,
  isScrolling: false,
  materialActive: initialMaterial,
  markActive: { name: '', id: 0 },
  closeDropdown: true,
  searchInternal: false,
  newMaterial: { mark: '', reference: '' },
};

const initialMark = {
  id: 0,
  name: '',
};

const initialSizingNote = {
  generate: null,
  room_height: null,
  altitude: null,
  temperature_mode: null,
  transmitter_type: null,
  construction_type: null,
  heat_pump_shutdown_temperature: null,
  power_temperature: null,
  electrical_resistance_power: null,
  other_heating: null,
  surface: null,
};

const materialInitialValues = (
  operation: IWorksiteOperation,
  arrayMaterialsDataPost: IPayloadMaterials[] | null
) => {
  const currentMaterial = arrayMaterialsDataPost?.find(
    (m) => m.worksite_operation_id === operation.id
  );

  const caracteristics = currentMaterial?.caracteristics
    ? JSON.parse(currentMaterial?.caracteristics)
    : { mark: { name: '' }, reference: { name: '' } };

  return {
    mark: caracteristics?.mark?.name || '',
    reference: caracteristics?.reference?.name || '',
  };
};

export {
  initialWorksite,
  initialSubcontractor,
  initialMaterial,
  initialSizingNote,
  materialInitialValues,
  materialInitialState,
  initialMark,
};
