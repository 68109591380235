import {
  useState,
  useRef,
  useEffect,
  SetStateAction,
  Dispatch,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { Modal } from '@components/Modal';
import { storeProduct } from '@models/materials/apiRequests/materialsRequests';
import { IMaterial } from '@models/worksiteCreation/utils/types/worksitesType';
import { getListMeterialsPerOperation } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import {
  initialMaterial,
  materialInitialState,
} from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import { IBrandMaterials } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { v4 } from 'uuid';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';

interface ProductSelectorProps {
  brandActive: IBrandMaterials;
  operationCode: string;
  setListMaterials: Dispatch<SetStateAction<IMaterial[]>>;
  onProductSelected: (material: IMaterial) => void;
  listMaterials: IMaterial[];
  searchInternal: boolean;
  setMaterialActive: Dispatch<SetStateAction<IMaterial>>;
  setNewMaterial?: Dispatch<
    SetStateAction<{ mark: string; reference: string }>
  >;
  resetSignal: number;
}

function ProductSelector({
  brandActive,
  operationCode,
  setListMaterials,
  onProductSelected,
  listMaterials,
  searchInternal,
  setMaterialActive,
  setNewMaterial,
  resetSignal,
}: ProductSelectorProps) {
  const { t } = useTranslation();
  const { readOnly } = useContext(WorksiteCreationContext);
  const [productSearchValue, setProductSearchValue] = useState<string>('');
  const [productPage, setProductPage] = useState<number>(1);
  const [lastPageProduct, setLastPageProduct] = useState<number>(2);
  const [isLoadingProduct, setIsLoadingProduct] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [closeDropdown, setCloseDropdown] = useState<boolean>(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [timeOut, setTimeOut] = useState<NodeJS.Timeout | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [resetSignalIsolated, setResetSignalIsolated] = useState<number>(0);

  const listInnerRefProduct = useRef<HTMLDivElement>(null);

  const resetValues = () => {
    setProductSearchValue('');
    setProductPage(1);
    setIsSearching(false);
    setIsScrolling(false);
    setLastPageProduct(2);
    setTimeOut(null);
    setIsLoadingProduct(false);
    setMaterialActive(initialMaterial);
    setCloseDropdown(true);
    if (setNewMaterial) {
      setNewMaterial(materialInitialState.newMaterial);
    }
  };
  const createProduct = async () => {
    setShowConfirmationModal(false);
    await storeProduct(brandActive.name, productSearchValue, t).then(
      (response) => {
        if (response) {
          const productNameUpperCase = productSearchValue.toUpperCase();
          const newMaterial = {
            ...initialMaterial,
            caracteristics: JSON.parse(response.caracteristics),
            id: response.id,
            material_id: response.material_id,
            name: response.name,
          };
          setListMaterials((prevMaterials) => [...prevMaterials, newMaterial]);
          setProductSearchValue(productNameUpperCase);
          setCloseDropdown(true);
          onProductSelected(newMaterial);
        }
      }
    );
  };

  const handleAddProduct = () => {
    setShowConfirmationModal(true);
  };

  const infiniteScroll = () => {
    setIsScrolling(true);
    setIsSearching(true);
    if (
      listInnerRefProduct.current &&
      !isLoadingProduct &&
      productPage < lastPageProduct
    ) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerRefProduct.current;
      if (scrollTop + clientHeight >= scrollHeight - 1.25) {
        setProductPage((prevState) => prevState + 1);
      }
    }
  };

  const getList = async (mark?: string, internal?: boolean) => {
    await getListMeterialsPerOperation(
      setIsLoadingProduct,
      mark ? '' : productSearchValue,
      productPage,
      operationCode,
      setListMaterials,
      isScrolling,
      setLastPageProduct,
      mark || brandActive.name,
      internal || searchInternal
    );
  };

  const onSearchProduct = (value: string) => {
    setIsScrolling(false);
    setCloseDropdown(false);
    setIsSearching(true);
    setProductPage(1);
    setProductSearchValue(value);
  };

  const handleProductSelection = (material: IMaterial) => {
    onProductSelected(material);
    setIsSearching(false);
    setProductSearchValue(material.name);
    setCloseDropdown(true);
  };

  useEffect(() => {
    if (resetSignal) {
      resetValues();
      setResetSignalIsolated(resetSignal);
    }
  }, [resetSignal]);

  useEffect(() => {
    if (isSearching) {
      if (timeOut) {
        clearTimeout(timeOut);
        setTimeOut(null);
      }
      const timeout = setTimeout(() => {
        getList();
      }, 350);
      setTimeOut(timeout);
    }
  }, [productSearchValue, isSearching]);

  useEffect(() => {
    if (productPage > 1) {
      getList();
    }
  }, [productPage]);

  useEffect(() => {
    if (brandActive.id !== null && brandActive.id !== 0) {
      getList();
    }
  }, [brandActive.id]);

  return (
    <>
      <InputSelect
        required
        label={`${t('worksite_creation.material.reference')}`}
        placeholder={t('worksite_creation.material.search_product')}
        showClearButton={productSearchValue !== ''}
        valueInput={productSearchValue}
        isAutoComplete
        disabled={brandActive.name === '' || readOnly}
        isLoading={isLoadingProduct}
        callbackOnSearch={(value) => onSearchProduct(value)}
        closeDropdown={closeDropdown}
        setCloseDropdown={setCloseDropdown}
        addClass="w-full"
        dataTestIdSearch="search_material_product"
        resetValue={resetSignal !== resetSignalIsolated}
        onClear={() => resetValues()}
      >
        <div
          className="flex flex-col max-h-[25rem]"
          onScroll={infiniteScroll}
          ref={listInnerRefProduct}
        >
          {listMaterials.map((material, index) => (
            <button
              onClick={() => {
                handleProductSelection(material);
              }}
              className="flex items-center text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey border-r border-r-borderGrey"
              key={v4()}
              type="button"
              data-test-id={`option_product_${index + 1}`}
            >
              {material.name} - {material.caracteristics.mark.name}
            </button>
          ))}
          {productSearchValue !== '' &&
            !listMaterials.some(
              (m) => m.name.toLowerCase() === productSearchValue.toLowerCase()
            ) && (
              <button
                type="button"
                className="text-blueOpx rounded-b-default cursor-pointer flex items-center text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey border-r border-r-borderGrey"
                onClick={handleAddProduct}
              >
                {`+ ${t('brand.products.add')}`}
              </button>
            )}
        </div>
      </InputSelect>
      {showConfirmationModal && (
        <Modal
          title={`${t('brand.products.add')}`}
          btnCancel
          textBtnConfirm={`${t('buttons.confirm')}`}
          setIsModal={setShowConfirmationModal}
          onConfirmClick={createProduct}
          backgroundTransparent
        >
          <div className="mt-[1rem]">
            {`${t('brand.products.add')} : ${productSearchValue} ?`}
          </div>
        </Modal>
      )}
    </>
  );
}
export default ProductSelector;

ProductSelector.defaultProps = {
  setNewMaterial: undefined,
};
