import { useCallback, useContext, useEffect, useState } from 'react';
import {
  IArrayInput,
  IGraph,
  IOneArrayKey,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import { ColorCube } from '@components/atomic/ColorCube';
import { TrashIcon } from '@assets/images/svgComponents';
import { red } from '@assets/color';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputText } from '@components/atomic/inputs/InputText';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useTranslation } from 'react-i18next';
import ElementCardInArray from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/ElementCardInArray';
import { graphOperationConditionIsOk } from '@models/worksiteCreation/utils/functions';
import ElementMaterialInArray from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/ElementMaterialInArray';

interface IOneArrayKeyProps {
  graph: IGraph;
  oneArrayKeyDatas: IOneArrayKey;
  operationIndex: number;
  operationCode: string;
}

function OneArrayKey({
  graph,
  oneArrayKeyDatas,
  operationIndex,
  operationCode,
}: IOneArrayKeyProps) {
  const { t } = useTranslation();

  const { updateSimulatorDataOperation, simulatorDataOperations, readOnly } =
    useContext(WorksiteCreationContext);
  const { watch, control, setValue, setError, clearErrors, unregister } =
    useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${operationIndex}.${graph.key}.${oneArrayKeyDatas.key}`,
  });

  const [initialisedWithSavedData, setInitialisedWithSavedData] =
    useState<boolean>(false);

  const formValuesOperation = watch(String(operationIndex));
  const formValuesOperationMultipleArray =
    formValuesOperation && formValuesOperation.operation
      ? formValuesOperation.operation[graph.key.split('.')[1]]
      : undefined;

  const checkIfErrorMinMax = (element: IArrayInput, elementKey: string) => {
    const { minValue, maxValue } = element;
    if (minValue || maxValue) {
      const value = watch(elementKey) ? Number(watch(elementKey)) : undefined;
      if (value !== undefined) {
        if (minValue && value < minValue) {
          return t('worksite_creation.simulation.error_min_value', {
            minValue,
          });
        }
        if (maxValue && value > maxValue) {
          return t('worksite_creation.simulation.error_max_value', {
            maxValue,
          });
        }
      }
    }
    return undefined;
  };

  const onChangeForm = (element?: IArrayInput, elementKey?: string) => {
    if (formValuesOperationMultipleArray) {
      if (element && elementKey) {
        const error = checkIfErrorMinMax(element, elementKey);
        if (error) {
          setError(elementKey, { message: error });
        } else {
          clearErrors(elementKey);
        }
      }
      updateSimulatorDataOperation((prevState) =>
        prevState.map((item, index) =>
          index === operationIndex
            ? { ...item, [graph.key]: formValuesOperationMultipleArray }
            : item
        )
      );
    }
  };

  const handleAddElement = useCallback(() => {
    append({});
  }, [append]);

  const handleDeleteElement = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  // Initialisation des champs selon oneArrayKeyDatas.min
  useEffect(() => {
    if (!initialisedWithSavedData && formValuesOperationMultipleArray) {
      const savedData =
        simulatorDataOperations &&
        simulatorDataOperations[operationIndex] &&
        simulatorDataOperations[operationIndex][graph.key] &&
        (simulatorDataOperations[operationIndex][graph.key] as any)[
          oneArrayKeyDatas.key
        ];

      if (savedData && savedData.length > 0 && fields.length === 0) {
        // Initialiser les champs avec les données sauvegardées
        savedData.forEach((item: { [x: string]: any }, index: number) => {
          append({});
          Object.keys(item).forEach((key) => {
            setValue(
              `${operationIndex}.${graph.key}.${oneArrayKeyDatas.key}.${index}.${key}`,
              item[key]
            );
          });
        });
      } else if (oneArrayKeyDatas.min && fields.length < oneArrayKeyDatas.min) {
        for (let i = fields.length; i < oneArrayKeyDatas.min; i += 1) {
          append({});
        }
      }
      setInitialisedWithSavedData(true);
      onChangeForm();
    }
  }, [
    oneArrayKeyDatas.min,
    simulatorDataOperations,
    operationIndex,
    graph.key,
    oneArrayKeyDatas.key,
    formValuesOperationMultipleArray,
    watch(String(operationIndex)),
  ]);

  // Sert à vérifier si les conditions sont remplies pour chaque éléments du multipleArrayKeys venant du graphe des opérations
  const conditionOk = (
    element: IArrayInput,
    oneArrayKey: string,
    index: number
  ): boolean => {
    // Logique de vérification des conditions
    const checkConditions = (
      elements: { [key: string]: string },
      dataArray: { [key: string]: string | number }[]
    ): boolean => {
      return Object.entries(elements).every(([key, condition]) => {
        const matchingObject = dataArray.find((obj) => key in obj);

        if (matchingObject) {
          const dataValue = matchingObject[key];
          return graphOperationConditionIsOk(condition, dataValue); // fonction utilitaire qui permet de comparer les valeurs du champ avec les conditions de ce champ
        }
        return false;
      });
    };

    const inputValues = formValuesOperationMultipleArray[oneArrayKey][index];
    let returnOk = true;

    // On vérifie si toutes les conditions de l'élément sont remplies (élément avec la clé "conditions)
    if (element.conditions && formValuesOperationMultipleArray[oneArrayKey]) {
      const conditions = element.conditions;

      returnOk = conditions.every((condition) =>
        checkConditions(condition, [inputValues])
      );
    }

    // On vérifie si au moins une des conditions de l'élément est remplie (élément avec la clé "oneCondition)
    if (element.oneCondition && formValuesOperationMultipleArray[oneArrayKey]) {
      const conditions = element.oneCondition;

      // on vérifie si une des conditions est remplie
      returnOk = conditions.some((condition) =>
        checkConditions(condition, [inputValues])
      );

      // si aucune condition n'est remplie, on se base sur les sous-conditions cumulatives si elles existent
      const subCondition: any = conditions.find(
        (cond: any) => 'cumulativeConditions' in cond
      );

      if (!returnOk && subCondition) {
        returnOk = subCondition.cumulativeConditions.every((subCond: any) =>
          checkConditions(subCond, [inputValues])
        );
      }
    }

    // Supprime l'élément dont les conditions ne sont pas remplies mais existent dans le formulaire
    if (inputValues[element.key] !== undefined && !returnOk) {
      unregister(
        `${operationIndex}.${graph.key}.${oneArrayKeyDatas.key}.${index}.${element.key}`
      );
    }
    return returnOk;
  };

  return (
    <>
      <p className="p-3 w-full border-b border-borderGrey">
        {oneArrayKeyDatas.title}
      </p>
      <div
        key={oneArrayKeyDatas.key}
        className="text-[.875rem] border border-borderGrey rounded-default"
      >
        <div className="p-3 flex flex-col space-y-2">
          {fields.map((fieldRecord, index) => {
            // On filtre les éléments en fonction des conditions du graphe des opérations
            const filteredOneArrayKeyDatas = oneArrayKeyDatas.elements.filter(
              (element) => conditionOk(element, oneArrayKeyDatas.key, index)
            );

            return (
              <>
                {oneArrayKeyDatas.subtitle && (
                  <div>{`${oneArrayKeyDatas.subtitle}  ${index + 1}`}</div>
                )}
                <div
                  key={fieldRecord.id}
                  className={`flex space-x-2 items-center pb-2 pt-4 ${
                    index !== fields.length - 1
                      ? 'border-b border-borderGrey pb-4'
                      : ''
                  }`}
                >
                  <div
                    className={`w-full grid grid-cols-${oneArrayKeyDatas.gridType} gap-x-2 gap-y-4`}
                  >
                    {filteredOneArrayKeyDatas.map((element) => {
                      const elementKey = `${operationIndex}.${graph.key}.${oneArrayKeyDatas.key}.${index}.${element.key}`;
                      let colWidth = element.colWidth ?? 1;
                      if (colWidth && colWidth > oneArrayKeyDatas.gridType) {
                        colWidth = oneArrayKeyDatas.gridType;
                      }
                      if (element.type === 'card') {
                        return (
                          <div
                            key={elementKey}
                            className={`col-span-${colWidth}`}
                          >
                            <ElementCardInArray
                              element={element}
                              elementKey={elementKey}
                            />
                          </div>
                        );
                      }
                      if (element.type === 'input-material') {
                        return (
                          <div
                            key={elementKey}
                            className={`col-span-${colWidth}`}
                          >
                            <ElementMaterialInArray
                              element={element}
                              elementKey={elementKey}
                              operationCode={operationCode}
                            />
                          </div>
                        );
                      }
                      return (
                        <div
                          key={elementKey}
                          className={`col-span-${colWidth}`}
                        >
                          <InputText
                            id={elementKey}
                            name={elementKey}
                            label={element.label}
                            placeholder={element.placeholder ?? element.label}
                            onChange={() => onChangeForm(element, elementKey)}
                            typeNumber={element.type === 'input-number'}
                            valueInitialInput={element.defaultValue}
                            key={elementKey}
                            disabled={readOnly}
                            required
                            addClassToInput={
                              readOnly ? 'bg-backgroundBody' : ''
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                  {!readOnly &&
                    oneArrayKeyDatas.canBeIncreased &&
                    fields.length > (oneArrayKeyDatas.min || 0) && (
                      <ColorCube
                        opacity
                        onClick={() => {
                          handleDeleteElement(index);
                          onChangeForm();
                        }}
                        size="1.5rem"
                        numberOrIcon={<TrashIcon />}
                        color={red}
                      />
                    )}
                </div>
              </>
            );
          })}
          {!readOnly &&
            oneArrayKeyDatas.canBeIncreased &&
            (!oneArrayKeyDatas.max || fields.length < oneArrayKeyDatas.max) && (
              <ButtonOpx
                label={
                  oneArrayKeyDatas.elementToAdd
                    ? `+ ${t('buttons.add')} ${oneArrayKeyDatas.elementToAdd}`
                    : `+ ${t('buttons.add_element')}`
                }
                small
                onClick={() => {
                  handleAddElement();
                  onChangeForm();
                }}
                type="tierciary"
              />
            )}
        </div>
      </div>
    </>
  );
}

export default OneArrayKey;
