import { FormAddress } from '@components/formAddress/FormAddress';
import { TextError } from '@components/TextError';
import { sendInfoLogement } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, /* useMemo , */ useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddressType } from '@utils/enums';
import { convertToFormAddressDatas } from '@utils/utils';
import { CadastreLocation } from './CadastreLocation';
import { UndividedHousing } from './UndividedHousing';

function InfoLogement() {
  const methods = useForm();

  const { handleSubmit } = methods;
  const { t } = useTranslation();

  const {
    updateInformationLogementDataPost,
    worksiteDatas,
    informationLogementDataPost,
    updateIsLoading,
    updateWorksiteDatas,
    simulatorData,
    updateDisabledNextButton,
    nextStepWorksiteCreation,
    worksiteAddress,
    updateWorksiteAddressFromKeyValue,
    worksiteOperationType,
    readOnly,
  } = useContext(WorksiteCreationContext);

  const [errorLogement, setErrorLogement] = useState<string>('');
  const [undividedHousing, setUndividedHousing] = useState<boolean>(false);
  const { need_cadastral_datas, address_has_street_number } = worksiteDatas;
  const [isManualAddressOwner, setIsManualAddressOwner] =
    useState<boolean>(false);

  const {
    city,
    zipCode,
    streetName,
    numberStreet,
    country,
    parcelNumber,
    parcelPrefix,
    parcelSection,
  } = worksiteAddress;

  const defaultParcel =
    worksiteDatas.housing && worksiteDatas.housing.cadastral_parcel
      ? (() => {
          const [prefix, section, number] =
            worksiteDatas.housing.cadastral_parcel.split(' ');
          return prefix && section && number
            ? { prefix, section, number }
            : undefined;
        })()
      : undefined;

  const needAddressOwner = simulatorData['general.owner'] === 'landlord-owner';

  const onSubmit = async () => {
    if (worksiteDatas.need_fiscal_datas !== undefined) {
      if (!readOnly) {
        await sendInfoLogement(
          worksiteDatas.id,
          informationLogementDataPost,
          updateIsLoading,
          updateWorksiteDatas,
          setErrorLogement
        );
      }

      nextStepWorksiteCreation();
    }
  };

  useEffect(() => {
    updateInformationLogementDataPost(
      !needAddressOwner
        ? {
            indivision: undividedHousing,
            cadastral_parcel: `${parcelPrefix} ${parcelSection} ${parcelNumber}`,
          }
        : {
            indivision: undividedHousing,
            cadastral_parcel: `${parcelPrefix} ${parcelSection} ${parcelNumber}`,
            owner: {
              address: `${numberStreet} ${streetName}`,
              city,
              postal_code: zipCode,
              country,
              address_type: AddressType.BAILLEUR,
            },
          }
    );
  }, [
    parcelPrefix,
    parcelSection,
    parcelNumber,
    undividedHousing,
    city,
    zipCode,
    streetName,
    numberStreet,
    country,
  ]);

  useEffect(() => {
    const { cadastral_parcel, owner } = informationLogementDataPost;

    let isNextButtonDisabled = false;

    if (!address_has_street_number) {
      if (cadastral_parcel === '') {
        isNextButtonDisabled = true;
      } else if (
        simulatorData['general.owner'] === 'landlord-owner' &&
        (owner?.address === '' ||
          owner?.city === '' ||
          owner?.postal_code === '')
      ) {
        isNextButtonDisabled = true;
      } else if (
        (need_cadastral_datas === true && parcelNumber === '') ||
        parcelPrefix === '' ||
        parcelSection === ''
      ) {
        isNextButtonDisabled = true;
      }
    }

    updateDisabledNextButton(isNextButtonDisabled);
  }, [
    simulatorData,
    informationLogementDataPost,
    worksiteDatas,
    parcelPrefix,
    parcelSection,
    parcelNumber,
    address_has_street_number,
  ]);

  return (
    <FormProvider {...methods}>
      <form
        id="sendInfoLogement"
        className="space-y-[1.5rem]"
        onSubmit={
          handleSubmit
            ? handleSubmit(onSubmit as (data: FieldValues) => void)
            : undefined
        }
      >
        {worksiteDatas.need_indivision_datas &&
          worksiteDatas.need_fiscal_datas === true && (
            <UndividedHousing
              undividedHousing={undividedHousing}
              setUndividedHousing={setUndividedHousing}
              methods={methods}
            />
          )}
        {needAddressOwner && (
          <div className="mt-[1.5rem]">
            <p className="font-medium mb-[1.5rem]">
              {t('worksite_creation.create_worksite.address_landlord_owner')}
            </p>
            <FormAddress
              setIsManual={setIsManualAddressOwner}
              isManual={isManualAddressOwner}
              datas={convertToFormAddressDatas(worksiteAddress)}
              updateDatas={updateWorksiteAddressFromKeyValue}
              disabled={readOnly}
            />
          </div>
        )}

        <CadastreLocation
          worksiteOperationType={worksiteOperationType}
          worksiteAddress={worksiteAddress}
          updateWorksiteAddressFromKeyValue={updateWorksiteAddressFromKeyValue}
          readOnly={readOnly}
          required={!worksiteDatas.address_has_street_number}
          defaultParcel={defaultParcel}
        />

        <TextError errorMessage={errorLogement} />
      </form>
    </FormProvider>
  );
}

export { InfoLogement };
