import { useContext } from 'react';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';

import { useTranslation } from 'react-i18next';
import { ENTITY_TYPES, ROLES } from '@utils/roles';
import {
  navLinksSidebar,
  navLinksSidebarBottom,
} from '@components/navigation/NavLinks';
import { AuthContext } from '@context/authContext';
import { DIMENSIONS } from '@utils/utils';
import { DashboardDevIcon } from '@assets/images/svgComponents';
import { DASHBOARD_ROUTES } from '@utils/routesUrls';
import { ButtonNewWorksite } from './sidebar/ButtonNewWorksite';
import { HeaderSidebar } from './sidebar/HeaderSidebar';
import { SwitchRoleButton } from './sidebar/SwitchRoleAndEntity/SwitchRoleButton';
import { LinkButton } from './sidebar/LinkButton';
import { BottomSidebar } from './sidebar/BottomSidebar';

function Sidebar(): JSX.Element {
  const { sidebarMenuIsOpen, roleUser, userView } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const { sidebarmenu_open_percent_width, sidebarmenu_close_percent_width } =
    DIMENSIONS;

  const MENU_OPEN_WIDTH = `${sidebarmenu_open_percent_width}%`;
  const MENU_CLOSE_WIDTH = `${sidebarmenu_close_percent_width}%`;
  const DEV_MODE =
    (process.env.REACT_APP_BACK_OPX_API_URL as string).includes('develop') ||
    (process.env.REACT_APP_BACK_OPX_API_URL as string).includes('localhost');
  const isAdminMode = userView?.entity_type === ENTITY_TYPES.ADMINISTRATOR;
  return (
    <div
      className={`w-screen z-20 h-screen transform bg-darkBlue pb-[1.5rem] flex flex-col justify-between fixed inset-0 text-white whitespace-nowrap transition-all duration-500 ease-in-out ${
        sidebarMenuIsOpen
          ? `-translate-x-0 ${MENU_OPEN_WIDTH}`
          : `-translate-x-0 ${MENU_CLOSE_WIDTH} duration-500`
      }`}
      style={{
        width: sidebarMenuIsOpen ? MENU_OPEN_WIDTH : MENU_CLOSE_WIDTH,
      }}
    >
      <div>
        <HeaderSidebar />
        <div className="px-[1.5rem]">
          <SwitchRoleButton />
          {((roleUser === ROLES.PRODUCTION &&
            user?.permissions_names.includes('creer-chantier')) ||
            (roleUser === ROLES.MAR &&
              user?.permissions_names.includes('creer-chantier-mar'))) && (
            <ButtonNewWorksite />
          )}
        </div>
        <div className={sidebarMenuIsOpen ? 'px-[1.5rem]' : 'px-[.875rem]'}>
          <div className="w-full pt-[1.5rem] flex flex-col space-y-[1rem] border-b border-b-[#EEEEEE40] pb-[1.5rem]">
            {navLinksSidebar(t)
              .filter((navLinkFiltered) =>
                navLinkFiltered.roles
                  ? navLinkFiltered.roles.includes(roleUser)
                  : true
              )
              .filter((navLinkFilteredByEntity) =>
                navLinkFilteredByEntity.allowed_entities
                  ? navLinkFilteredByEntity.allowed_entities.includes(
                      userView?.entity_type || 0
                    )
                  : true
              )
              .filter((navLink) => {
                if (navLink.roles?.includes(ROLES.MAR)) {
                  return true;
                }
                return !(
                  navLink.name?.includes(t('sidebar.worksites')) &&
                  !user?.permissions_names.includes('gerer-chantier')
                );
              })
              .map((navLink) => (
                <div key={v4()}>
                  <LinkButton navLink={navLink} />
                </div>
              ))}

            {!isAdminMode && DEV_MODE && (
              <LinkButton
                navLink={{
                  icon: <DashboardDevIcon />,
                  name: t('sidebar.dashboard_dev'),
                  link: DASHBOARD_ROUTES.DASHBOARD_DEV,
                  roles: [ROLES.PRODUCTION, ROLES.GESTION],
                  dataTestId: 'navlink_dashboard_dev',
                }}
              />
            )}
          </div>
          <div className="w-full pt-[2rem] flex flex-col space-y-[1rem]">
            {navLinksSidebarBottom(t).map((navLink) => (
              <div key={v4()}>
                <LinkButton navLink={navLink} />
              </div>
            ))}
            <div />
          </div>
        </div>
      </div>
      <BottomSidebar />
    </div>
  );
}

export { Sidebar };
