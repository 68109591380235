/**
 * Format de courrier électronique respectant strictement la RFC 3696
 */
export const emailRegex =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-ZÀ-ÿ\-0-9]+\.)+[a-zA-ZÀ-ÿ]{2,}))$/;

export const phoneNumberRegex = /^(0\d{9}|\+\d{2}\d{9,10})$/; // +33 1 02 03 04 05 // 01 02 03 04 05 // +262 1 02 03 04 05
export const numberRegex = /^[0-9,.]*$/;

export const alphanumericRegex =
  /^[A-Za-z0-9\s-/\\'"’°«»áàâäãæçéèêëíìîïñóòôöõœúùûüÁÀÂÄÃÆÇÉÈÊËÍÌÎÏÑÓÒÔÖÕŒÚÙÛÜ.,!?`~@#$%^&*()_\-+={}[\]:/<>]*$/;

export const alphabeticRegex = /^[a-zA-ZÀ-ÿ\s]+$/;

// accepte les lettres de l'alphabet + les espace et les tiret (pour les noms composé par exemple)
export const nameRegex = /^[a-zA-ZÀ-ÿ\s'-]+$/;

export const dateRegex =
  /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(201[1-9]|20[2-9][0-9])$/;

export const emailValidationRegex =
  /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
export const siretRegex = /^[0-9]{14}$/;
export const taxNumberRegex = /^\d{13}$/;
export const taxNoticeReferenceRegex =
  /^(?=\d*\D?\d*$)(?=[a-zA-Z\d]{13,14}$)\d*\D?\d*$/;
export const ibanRegex = /^[A-Z0-9]{5,34}$/;
export const decimalRegex = /^-?\d*\.?\d{0,2}$/;
export const decimalRegexWithoutNegative = /^\d*,?\d{0,2}$/;
export const websiteRegex =
  /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,6})([/\w .-]*)*\/?$/;
export const parcelRegex = /^\d{3}\s[A-Za-z0-9]{2}\s\d{4}$/;
