/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { AuthContext } from '@context/authContext';
import { HeaderContext } from '@context/headerContext';

import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { TabInfosContracts } from '@models/contracts/components/TabInfosContracts';
import { TabVolumeDetail } from '@models/contracts/components/TabVolumeDetail';
import { TabPricePerOperation } from '@models/conventions/components/TabPricePerOperation';

import {
  getBuyingPrices,
  getContractInfos,
  getVolumeDetail,
  upsertTransfer,
} from '@models/contracts/apiRequests/contractRequests';

import { upsertLot } from '@models/lots/apiRequests/lotRequests';
import {
  noPermissions,
  verifyPermission,
} from '@models/contracts/utils/contractHelper';

import {
  IContractInfosGeneralType,
  IVolumeDetailType,
} from '@models/contracts/utils/contractTypes';

import {
  URL_TYPES,
  VISUALISER,
} from '@models/contracts/utils/contractConstants';
import { GlobalContext } from '@context/globalContext';
import { formatWord } from '@utils/format';
import { TabDeposits } from '@models/contracts/components/TabDeposits';
import { TabDeliveryOperations } from '@models/lots/components/TabDeliveryOperations';
import { ROLES, isMandatory, isOblige } from '@utils/roles';
import { isBefore, parse } from 'date-fns';
import { initDocumentActive } from '@utils/initialState';
import { LoaderKpiTab } from '@components/loaders/LoaderKpiTab';
import { LoaderDocument } from '@components/loaders/document/LoaderDocument';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import {
  CONTRACTS_ROUTES,
  CONTRACTS_ROUTES_WITH_ID,
  CONTRACTS_ROUTES_WITH_PARAMS,
  DEPOSITS_ROUTES_WITH_ID,
} from '@utils/routesUrls';

function ContractSheet() {
  const { t } = useTranslation();
  const { type: contractType, id: contractId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useContext(AuthContext);
  const { updateTitleHeader, updateTagHeader, refreshHeader } =
    useContext(HeaderContext);
  const {
    route,
    updateRoute,
    roleUser,
    updateDocumentActive,
    globalEnum,
    userView,
  } = useContext(GlobalContext);

  const [selectedMenu, setSelectedMenu] = useState<string>(
    `${t('convention.partner_information')}`
  );
  const [infosGeneral, setInfosGeneral] = useState<IContractInfosGeneralType>();
  const [volumeDetail, setVolumeDetail] = useState<IVolumeDetailType>();
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    setIsLoading(true);
    const resContract = await getContractInfos(Number(contractId));
    setInfosGeneral(resContract);
    setIsLoading(false);
  };

  const getDetails = async () => {
    setIsLoading(true);
    const resVolume = await getVolumeDetail(Number(contractId));
    setVolumeDetail(resVolume);
    setIsLoading(false);
  };
  const renderTransferButton = () => {
    return (
      <ButtonOpx
        label={`${t('contract.tab_volume_detail.new_transfer')}`}
        onClick={async () => {
          const res = await upsertTransfer({
            contract_id: Number(contractId),
          });
          navigate(DEPOSITS_ROUTES_WITH_ID(res.id).TRANSFER_VIEW);
        }}
      />
    );
  };

  const renderLotButton = () => {
    return (
      <ButtonOpx
        label={t('contractMandant.new_lot')}
        onClick={async () => {
          const res = await upsertLot({
            contract_id: Number(contractId),
          });
          navigate(
            `${DEPOSITS_ROUTES_WITH_ID(res.id).LOT_VIEW}?lot_number=${
              res.lot_number
            }`
          );
        }}
        disabled={!user?.permissions_names.includes('creer-lot')}
      />
    );
  };

  const contractTypeFormatted = useMemo(
    () => formatWord(contractType),
    [contractType]
  );

  const tabs = useMemo(() => {
    if (
      contractType &&
      [URL_TYPES.MANDANT, URL_TYPES.MANDANT_NON_DEPOSANT].includes(
        contractTypeFormatted
      )
    ) {
      const tabList = [
        t('convention.partner_information'),
        t('convention.tabs.operation_prices'),
      ];

      if (
        infosGeneral &&
        infosGeneral.is_deposit &&
        contractType &&
        contractType === URL_TYPES.MANDANT
      ) {
        tabList.push(t('contractMandant.deposits'));
      }

      if (
        infosGeneral &&
        contractType &&
        contractType === URL_TYPES.MANDANT_NON_DEPOSANT
      ) {
        // entite connectee est destinataire du contrat si is_vendeur=false && is_lot=== true
        if (
          roleUser === ROLES.GESTION &&
          !infosGeneral.is_vendeur &&
          infosGeneral.is_lot
        ) {
          tabList.push(t('contractMandant.delivery_operations'));
        }

        // entite connectee est le RAI du contrat
        if (infosGeneral.is_vendeur) {
          tabList.push(t('contractMandant.lot_operations'));
        }
      }

      return tabList;
    }

    return [t('convention.partner_information'), t('contract.volume_details')];
  }, [contractType, infosGeneral, roleUser, user]);

  const showTransferButton = useMemo(() => {
    if (volumeDetail) {
      const end_date = parse(
        volumeDetail.end_date || '',
        'yyyy-MM-dd',
        new Date()
      );
      const isBeforeToday = isBefore(end_date, new Date());
      return (
        (selectedMenu === tabs[1] || location.hash === '#detail') &&
        volumeDetail &&
        volumeDetail.is_vendeur &&
        !isBeforeToday
      );
    }
    return false;
  }, [volumeDetail, selectedMenu, location]);

  const showLotButton = useMemo(() => {
    if (infosGeneral) {
      const end_date = parse(
        infosGeneral.end_date || '',
        'yyyy-MM-dd',
        new Date()
      );
      const isBeforeToday = isBefore(end_date, new Date());
      return (
        roleUser === ROLES.GESTION &&
        infosGeneral &&
        infosGeneral.is_lot &&
        !infosGeneral.is_vendeur &&
        (selectedMenu === tabs[2] || location.hash === '#lots') &&
        !isBeforeToday
      );
    }
    return false;
  }, [roleUser, infosGeneral, selectedMenu, location]);

  useMemo(() => {
    if (location.hash === '#detail') {
      setSelectedMenu(tabs[1]);
    }
    if (location.hash === '#lots') {
      setSelectedMenu(tabs[2]);
    }
  }, [location.hash]);

  const isMandataire = useMemo(() => {
    return isMandatory(user);
  }, [isMandatory, user]);

  const isCreatorMandant = useMemo(() => {
    return (
      isOblige(user) &&
      infosGeneral?.created_by === user?.current_entity_type &&
      contractType &&
      [URL_TYPES.MANDANT, URL_TYPES.MANDANT_NON_DEPOSANT].includes(contractType)
    );
  }, [infosGeneral]);

  const isVolumeEngagedZero = useMemo(() => {
    return (
      infosGeneral?.convention_worksites.classique === 0 &&
      infosGeneral?.convention_worksites.precaire === 0
    );
  }, [infosGeneral]);

  const handleEditMandateContract = () => {
    navigate(CONTRACTS_ROUTES_WITH_ID(Number(contractId)).CONTRACTS_EDIT, {
      state: { copy: true, contract_type: infosGeneral?.contract_type },
    });
  };

  useEffect(() => {
    if (route.to === route.from) {
      updateRoute({ to: route.to, from: CONTRACTS_ROUTES.CONTRACTS });
    }
    updateDocumentActive(initDocumentActive);
    updateTitleHeader('loading');
  }, []);

  useEffect(() => {
    if (contractId) getData();
  }, [contractId]);

  useEffect(() => {
    if (infosGeneral) {
      const pageTitle = `${
        userView?.entity_id === infosGeneral?.entity.id
          ? infosGeneral.entityTo.company_name
          : infosGeneral.entity.company_name
      } - ${globalEnum.contract_type[infosGeneral.contract_type]} `;

      updateTitleHeader(pageTitle);
    }

    updateTagHeader(undefined);
    refreshHeader(true);
  }, [infosGeneral, contractType, isMandataire]);

  useEffect(() => {
    if (
      contractType &&
      [URL_TYPES.SALE, URL_TYPES.DELEGATION].includes(contractTypeFormatted) &&
      selectedMenu === tabs[1]
    ) {
      getDetails();
      navigate(
        `${
          CONTRACTS_ROUTES_WITH_PARAMS(contractType, contractId as string)
            .CONTRACT_VIEW
        }${location.hash || ''}`
      );
    }
  }, [contractId, contractType, selectedMenu]);

  if (
    user &&
    contractType &&
    !verifyPermission(
      user,
      [URL_TYPES.MANDANT, URL_TYPES.MANDANT_NON_DEPOSANT].includes(
        contractTypeFormatted
      )
        ? [VISUALISER.CONTRACT_MANDANT]
        : [VISUALISER.CONTRACT_VENTE],
      ![URL_TYPES.MANDANT, URL_TYPES.MANDANT_NON_DEPOSANT].includes(
        contractTypeFormatted
      )
    )
  ) {
    return noPermissions(t);
  }

  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={
          <TabsLeftPart
            titlesList={tabs}
            onClick={(title) => {
              setSelectedMenu(title);
              navigate(
                CONTRACTS_ROUTES_WITH_PARAMS(
                  contractType as string,
                  contractId as string
                ).CONTRACT_VIEW
              );
            }}
            activeButton={
              location.hash === '#detail'
                ? tabs[1]
                : location.hash === '#lots'
                ? tabs[2]
                : undefined
            }
          />
        }
        rightPart={
          <div className="flex items-center">
            {contractType
              ? [URL_TYPES.SALE, URL_TYPES.DELEGATION].includes(
                  contractTypeFormatted
                ) && showTransferButton
                ? renderTransferButton()
                : [URL_TYPES.MANDANT, URL_TYPES.MANDANT_NON_DEPOSANT].includes(
                    contractType
                  ) && showLotButton
                ? renderLotButton()
                : undefined
              : undefined}
            {isCreatorMandant && isVolumeEngagedZero ? (
              <ButtonOpx
                label={t('contract.mandate.update.button')}
                onClick={() => handleEditMandateContract()}
                dataTestId="button_sub_header"
                addClass="mr-1 px-[1rem] py-[0.625rem]"
              />
            ) : undefined}
          </div>
        }
      />
      {isLoading && selectedMenu !== t('contract.volume_details') && (
        <div className="w-full flex min-h-10 space-x-[1.5rem]">
          <div className="w-[60%]">
            <div className="h-[7.25rem] flex justify-between space-x-[1.5rem]">
              <LoaderKpiTab />
              <LoaderKpiTab />
              <LoaderKpiTab />
            </div>
            <div className="w-full h-full mt-[1.5rem] border-borderGrey">
              <LoaderDivSkeleton height="26rem" backgroundColor="transparent" />
            </div>
          </div>
          <div className="w-[40%] h-full">
            <LoaderDocument />
          </div>
        </div>
      )}
      {contractType && selectedMenu === tabs[0] && (
        <TabInfosContracts
          contractId={Number(contractId)}
          contractType={contractType}
          data={infosGeneral}
          refresh={() => getData()}
          isLoading={isLoading}
        />
      )}
      {contractType &&
        [URL_TYPES.MANDANT, URL_TYPES.MANDANT_NON_DEPOSANT].includes(
          contractTypeFormatted
        ) &&
        selectedMenu === tabs[1] && (
          <TabPricePerOperation
            id={Number(contractId)}
            fetchData={getBuyingPrices}
            isConvention={false}
          />
        )}
      {contractType &&
        contractType === URL_TYPES.MANDANT &&
        selectedMenu === tabs[2] && (
          <TabDeposits contractId={Number(contractId)} />
        )}
      {contractType &&
        contractType === URL_TYPES.MANDANT_NON_DEPOSANT &&
        (selectedMenu === tabs[2] || location.hash === '#lots') && (
          <TabDeliveryOperations contractId={Number(contractId)} />
        )}
      {contractType &&
        [URL_TYPES.SALE, URL_TYPES.DELEGATION].includes(
          contractTypeFormatted
        ) &&
        selectedMenu === tabs[1] && (
          <TabVolumeDetail
            contractId={Number(contractId)}
            data={volumeDetail}
            refresh={() => getDetails()}
          />
        )}
    </div>
  );
}

export { ContractSheet };
