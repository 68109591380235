import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { WorksitesCard } from '@models/worksites/components/worksitesList/WorksitesCard';
import { LoaderWorksitesList } from '@components/loaders/worksites/LoaderWorksitesList';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { useTranslation } from 'react-i18next';
import { blueOpx } from '@assets/color';
import { getConventionWorksites } from '../apiRequests/conventionRequests';

interface ITabWorksitesProps {
  conventionId: number;
}

function TabWorksites({ conventionId }: ITabWorksitesProps) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(5);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [worksitesList, setWorksitesList] = useState<IWorksiteDetails[]>([]);
  const [paginationData, setPaginationData] = useState<
    Record<string, number | null> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);

  const numberLoaderCards = 5;

  const getList = async (page: number) => {
    setIsLoading(true);
    const res = await getConventionWorksites(
      conventionId,
      page,
      resultsPerPage
    );
    if (res) {
      setWorksitesList(res.data);
      setPaginationData({
        current_page: res.meta.current_page,
        last_page: res.meta.last_page,
        total: res.meta.total,
      });
      if (res.current_page > pagesDatas.length) {
        setPagesDatas([
          ...pagesDatas,
          {
            data: res.data,
            meta: {
              current_page: res.current_page,
              last_page: res.last_page,
              total: res.total,
            },
          },
        ]);
      }
    }
    setIsLoading(false);
  };

  const pageAlreadyLoad = (page: number) => {
    if (pagesDatas[page - 1]) {
      setPaginationData(pagesDatas[page - 1].meta);
    } else {
      getList(page);
    }
  };

  useEffect(() => {
    if (conventionId > 0) {
      getList(1);
    }
  }, [conventionId]);

  useEffect(() => {
    getList(1);
  }, [resultsPerPage]);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-2 ml-1">
        <div>
          {paginationData?.total !== undefined && (
            <div>
              {t('pagination.total_results')} : {paginationData.total}
            </div>
          )}
        </div>
        <div>
          <ResultsPerPageButton
            resultsPerPage={resultsPerPage}
            onChange={(value) => {
              setResultsPerPage(value);
              setCurrentPage(1);
            }}
            options={[5, 10, 20, 50]}
            colorPagination={blueOpx}
          />
        </div>
      </div>

      {isLoading ? (
        <div className="w-full overflow-y-scroll flex-col flex space-y-[1.5rem]">
          {[...Array(numberLoaderCards)].map(() => (
            <div key={v4()}>
              <LoaderWorksitesList />
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full" data-test-id="worksites_list">
          {worksitesList.length === 0 ? (
            <div className="list_noElements flex items-center justify-center h-36 text-danger">
              {t('list.no_result')}
            </div>
          ) : (
            <div className="w-full flex-col flex space-y-[1.5rem]">
              {worksitesList.map((details: IWorksiteDetails) => (
                <WorksitesCard key={details.id} worksiteDetails={details} />
              ))}
            </div>
          )}
        </div>
      )}
      <ListPagination
        getData={(page) => {
          setCurrentPage(page);
          if (page > pagesDatas.length) {
            getList(page);
          } else {
            pageAlreadyLoad(page);
          }
        }}
        paginationData={paginationData}
        resultsPerPage={resultsPerPage}
        currentPage={currentPage}
      />
    </div>
  );
}

export { TabWorksites };
