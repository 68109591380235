import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import { isBefore, parse, startOfDay } from 'date-fns';
import { CardInformations } from '@components/informations/CardInformations';
import {
  getConventionModelGeneralInfoCard,
  getInfos,
} from '@models/conventions/utils/conventionHelper';
import { IConventionModelType } from '@models/conventions/utils/conventionTypes';
import { iconBlue, lightBlue, red } from '@assets/color';
import { convertKiloToMega, isBeforeToday } from '@utils/functions';
import { KpiTab } from '@components/atomic/KpiTab';
import { IKPITab } from '@../../types/globalTypes';
import { WorksiteIcon } from '@assets/images/svgComponents';
import { GlobalContext } from '@context/globalContext';

interface ITabInfosConventionModelProps {
  model: IConventionModelType;
}

function TabInfosConventionModel({ model }: ITabInfosConventionModelProps) {
  const { t } = useTranslation();
  const { roleUser, globalEnum } = useContext(GlobalContext);

  const gisementClassique = model?.worksites_created_total_Kwhc_classic || null;
  const gisementPrecaire = model?.worksites_created_total_Kwhc_precaire || null;
  const vTotalClassic = Number(model.volume_classique_kwhc);
  const vTotalPrecaire = Number(model.volume_precaire_kwhc);

  const ratioClassic =
    vTotalClassic > 0 ? Number(gisementClassique) / vTotalClassic : 0;
  const ratioPrecaire =
    vTotalPrecaire > 0 ? Number(gisementPrecaire) / vTotalPrecaire : 0;

  const kpiTabContracts: IKPITab[] = [
    {
      infos: [
        {
          title: t('convention.tab_info_general.KPITabs.nb_worksites'),
          subtitle: `${model.worksites_created_count || '0'}`,
        },
      ],
      icon: <WorksiteIcon />,
      colorIcon: iconBlue,
      dataTestId: 'nb_worksites',
    },
    {
      infos: [
        {
          title: t('global.KPITabs.volume_cee_classique'),
          subtitle: `${convertKiloToMega(
            gisementClassique
          )} / ${convertKiloToMega(vTotalClassic)} MWhc`,
        },
      ],
      colorIcon: '#46BDE2',
      progressBar: true,
      ratios: ratioClassic,
    },
    {
      infos: [
        {
          title: t('global.KPITabs.volume_cee_precaire'),
          subtitle: `${convertKiloToMega(
            gisementPrecaire
          )} / ${convertKiloToMega(vTotalPrecaire)} MWhc`,
        },
      ],
      colorIcon: '#916BE2',
      progressBar: true,
      ratios: ratioPrecaire,
    },
  ];

  const itemsInfoGeneral = useMemo(() => {
    return getConventionModelGeneralInfoCard(t, model, globalEnum);
  }, [t, model]);

  const isExpired = useMemo(() => {
    if (!model) return true;

    return isBeforeToday(model.end_date);
  }, [model]);

  const itemsInfoIntermediary = useMemo(() => {
    if (!model.intermediary_business) return null;
    return getInfos(
      t,
      model.intermediary_business,
      model.contact_intermediary_business,
      { typeContact1: 1, typeContactSign: 2 },
      (globalEnum as any).signature_status,
      true
    );
  }, [t, model, roleUser, isExpired]);

  const itemsInfoCreator = useMemo(() => {
    return getInfos(
      t,
      model.from_entity,
      model.contact_mandataire,
      { typeContact1: 3, typeContactSign: 2 },
      (globalEnum as any).signature_status,
      true
    );
  }, [t, model, roleUser, isExpired]);

  const renderInfosIntermediary = () => {
    const intermediary = model.intermediary_business;
    if (intermediary === null || itemsInfoIntermediary === null) {
      return undefined;
    }
    return (
      <CardInformations
        title={`${t('contract.business.tab_title')}`}
        data={{
          status: undefined,
          rows: itemsInfoIntermediary,
        }}
        loading={false}
      />
    );
  };

  const renderInfosGeneral = () => {
    const status = model.status;
    return (
      <CardInformations
        title={t('convention.general_information') || ''}
        data={{
          status: {
            color: isExpired ? red : lightBlue,
            label: isExpired
              ? t('convention.model.expired')
              : globalEnum.convention_model_status[`${status}`],
          },
          rows: itemsInfoGeneral,
        }}
        loading={false}
        dataTestId="informations_card"
      />
    );
  };

  const renderInfosPartner = () => {
    const rows = itemsInfoCreator;

    const title = `${t('convention.partner_information')} ${
      model.from_entity.company_name || ''
    }`;

    return (
      <CardInformations
        title={title}
        data={{
          status: undefined,
          rows,
        }}
        loading={false}
      />
    );
  };

  if (!model) return null;
  return (
    <div className="w-full">
      <div className="w-full grid grid-cols-1 gap-y-6">
        <KpiTab infos={kpiTabContracts} />
        {renderInfosGeneral()}
        {renderInfosIntermediary()}
        {renderInfosPartner()}
      </div>
    </div>
  );
}

export { TabInfosConventionModel };
