import { Modal } from '@components/Modal';
import {
  CivilityEnum,
  StepsEnumBeforeSimulation,
} from '@models/worksiteCreation/utils/enums';

import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { emailRegex, phoneNumberRegex } from '@utils/regex';
import {
  convertHexToRGBA,
  formatAddressAndLatLngFromForm,
} from '@utils/functions';
import { red } from '@assets/color';
import {
  IBeneficiary,
  IBeneficiaryAddress,
} from '@models/beneficiaries/utils/beneficiariesType';
import { CompanyActivityStatusEnum, OperationTypeEnum } from '@utils/enums';
import { CardForm } from '../../../CardForm';
import { FormContentCreateBeneficiary } from './FormContentCreateBeneficiary';
import { createBeneficiary } from '../../../../apiRequests/worksiteCreationRequests';
import { WorksiteCreationContext } from '../../../../utils/worksiteCreationContext';

interface IStepCreateBeneficiary {
  setStepFormBeforeSimulation?: Dispatch<
    SetStateAction<StepsEnumBeforeSimulation>
  >;
}

function StepCreateBeneficiary({
  setStepFormBeforeSimulation,
}: IStepCreateBeneficiary) {
  const { t } = useTranslation();
  const methods = useForm();
  const { watch, setValue } = methods;
  const {
    updateBeneficiary,
    updateIsModals,
    isModals,
    worksiteOperationType,
    partner,
    conventionActive,
    worksiteAddress,
    updateWorksiteAddressFromKeyValue,
  } = useContext(WorksiteCreationContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>(
    undefined
  );

  const [beneficiaryCivility, setBeneficiaryCivility] = useState({
    civility: t('forms.civility.mr'),
    value: CivilityEnum.MR,
  });
  const [beneficiaryAddress, setBeneficiaryAddress] =
    useState<IBeneficiaryAddress>();

  const valuesForm = watch();

  const checkIfOneValueIsEmpty = (values: string[]) => {
    if (values.includes('siret')) {
      if (!valuesForm.siret) return true;
      if (valuesForm.siret && valuesForm.siret.length !== 14) return true;
    }
    return values.some(
      (value) => !valuesForm[value] || valuesForm[value] === ''
    );
  };

  const subtitle =
    worksiteOperationType === OperationTypeEnum.B2C
      ? t(
          'worksite_creation.form_modal_before_simulation.subtitle_form_create_beneficiary_b2c'
        )
      : t(
          'worksite_creation.form_modal_before_simulation.subtitle_form_create_beneficiary_b2b'
        );
  const textButton = t('buttons.confirm');

  const valuesFormAddressNotComplete =
    worksiteOperationType === OperationTypeEnum.B2B
      ? checkIfOneValueIsEmpty(['city', 'zipcode', 'address'])
      : checkIfOneValueIsEmpty(['city', 'zipcode', 'address', 'country']);

  const infosToCheck =
    worksiteOperationType === OperationTypeEnum.B2B
      ? ['company_name', 'siret', 'firstname', 'lastname', 'signatory_function']
      : ['firstname', 'lastname'];

  const valuesFormBeneficiaryInformations =
    checkIfOneValueIsEmpty(infosToCheck) ||
    !phoneNumberRegex.test(valuesForm.phone) ||
    !phoneNumberRegex.test(
      valuesForm.phoneFix && valuesForm.phoneFix !== ''
        ? valuesForm.phoneFix
        : '0102030405'
    ) ||
    !emailRegex.test(valuesForm.email);

  const checkIfFormComplete = !(
    valuesFormAddressNotComplete || valuesFormBeneficiaryInformations
  );

  // CREATION D'UN BENEFICIAIRE
  const onSubmitCreateBeneficiary = async (formData: FieldValues) => {
    if (checkIfFormComplete) {
      const { address, postalCode, city, country, latLngToUse } =
        await formatAddressAndLatLngFromForm(
          formData,
          beneficiaryAddress,
          updateWorksiteAddressFromKeyValue
        );

      let dataToSubmit: IBeneficiary = {
        id: null,
        civility: beneficiaryCivility.value,
        firstname: formData.firstname,
        lastname: formData.lastname,
        address: {
          address_type: 1,
          address,
          postal_code: postalCode,
          city,
          country,
          elevation: null,
          worksite_id: null,
          latitude: latLngToUse.lat,
          longitude: latLngToUse.lng,
          additional_address: null,
          selected: true,
        },
        fixe_phone: formData.phoneFix ? formData.phoneFix : null,
        mobile_phone: formData.phone,
        email: formData.email,
        worksites: [],
      };

      if (worksiteOperationType === OperationTypeEnum.B2B)
        dataToSubmit = {
          ...dataToSubmit,
          share_capital: '',
          siret: formData.siret,
          siren: formData.siret.slice(0, 9),
          company_name: formData.company_name,
          civility: beneficiaryCivility.value, // civilité du signataire du bénéficiaire
          signatory_function: formData.signatory_function,
        };
      else {
        updateBeneficiary(dataToSubmit);
      }

      const creationValidate = await createBeneficiary(
        setIsLoading,
        updateBeneficiary,
        dataToSubmit,
        setIsError,
        setErrorMessages,
        partner ? conventionActive.id : undefined
      );

      if (creationValidate) {
        updateIsModals({
          formBeforeSimulation: false,
          bindBeneficiary: false,
          createBeneficiary: false,
          saveAndQuit: false,
          deleteWorksite: false,
        });
      }
    }
  };

  const onBackClick = () => {
    if (setStepFormBeforeSimulation && !isModals.createBeneficiary) {
      setStepFormBeforeSimulation(StepsEnumBeforeSimulation.BIND_BENEFICIARY);
    } else {
      updateIsModals({ ...isModals, createBeneficiary: false });
    }
  };

  return (
    <Modal
      title={t(
        'worksite_creation.form_modal_before_simulation.create_beneficiary'
      )}
      withArrowBack
      onBackClick={() => onBackClick()}
      textBtnConfirm={textButton}
      formId="createBeneficiary"
      isLoading={isLoading}
      btnConfirmDisabled={
        !checkIfFormComplete ||
        isLoading ||
        valuesForm.status === CompanyActivityStatusEnum.INACTIVE
      }
    >
      <div>
        <CardForm
          title={t(
            'worksite_creation.form_modal_before_simulation.title_form_create_beneficiary'
          )}
          subtitle={subtitle}
          idForm="createBeneficiary"
          onSubmit={onSubmitCreateBeneficiary}
          methods={methods}
        >
          <FormContentCreateBeneficiary
            beneficiaryCivility={beneficiaryCivility}
            setBeneficiaryCivility={setBeneficiaryCivility}
            setBeneficiaryAddress={setBeneficiaryAddress}
            valuesForm={valuesForm}
            setValue={setValue}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            worksiteAddress={worksiteAddress}
            updateWorksiteAddressFromKeyValue={
              updateWorksiteAddressFromKeyValue
            }
          />
        </CardForm>
        {isError && !isLoading && (
          <div
            className="rounded-default p-[.5rem] mt-[.5rem] text-red"
            style={{
              backgroundColor: convertHexToRGBA(red, 0.1),
            }}
          >
            {errorMessages
              ? errorMessages.map((errorMessage) => <p>{errorMessage}</p>)
              : t('forms.error_server')}
          </div>
        )}
      </div>
    </Modal>
  );
}

export { StepCreateBeneficiary };

StepCreateBeneficiary.defaultProps = {
  setStepFormBeforeSimulation: null,
};
