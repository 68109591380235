/* eslint-disable react/no-unstable-nested-components */
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';

import { GlobalContext } from '@context/globalContext';
import { AuthContext } from '@context/authContext';
import { HeaderContext } from '@context/headerContext';

import { ListTable, IColumn, IRow } from '@components/ListTable';
import { SubHeader } from '@components/subHeader/SubHeader';
import { Tag } from '@components/atomic/Tag';
import { ListPagination } from '@components/atomic/pagination/ListPagination';

import { getPartnersList } from '@models/partners/apiRequests/partnersRequest';
import { IPartnerType } from '@models/partners/utils/types/partnersType';
import { PartnersNewButton } from '@models/partners/components/PartnersNewButton';

import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  resetPagination,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { PARTNERS_MENU_MAPPING } from '@models/partners/utils/partnersConstants';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { useNavigate } from 'react-router-dom';
import { ENTITY_TYPES } from '@utils/roles';
import { SupportContactIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { PARTNERS_ROUTES } from '@utils/routesUrls';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';
import { blueOpx } from '@assets/color';
import { getRelatedModelBadge } from '../utils/partnersFunctions';
import { AddSubcontractorForm } from './subcontractors/AddSubcontractorForm';
import { ModalInstallerInvitation } from './invitation/ModalInstallerInvitation';

interface IPartnersListProps {
  entityType: number | null;
  isInstaller: boolean;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  changeTab: (newEntityType: number) => void;
}

function PartnersList({
  entityType,
  isInstaller,
  selectedTab,
  setSelectedTab,
  changeTab,
}: IPartnersListProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { partnersMenu, userView, globalEnum } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const { updateTitleHeader, refreshHeader, updateDisplayBackButtonHeader } =
    useContext(HeaderContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IPartnerType[]>([]);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [resultsPerPage, setResultsPerPage] = useState<number>();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);
  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>();
  const [modal, setModal] = useState<boolean>(false);
  const [relatedModel, setRelatedModel] = useState<string>('contract');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const tabs = useMemo(() => {
    if (isInstaller) return [];

    const partnersTabs = Object.values(partnersMenu)
      .filter((elt) => Number(elt.entityType) !== ENTITY_TYPES.INSTALLATEUR)
      .map((item) =>
        t(String(PARTNERS_MENU_MAPPING[Number(item.entityType)]?.title))
      );

    return [t('partners.all'), ...partnersTabs];
  }, [partnersMenu, isInstaller]);

  const getData = async (pageParam?: number) => {
    const page = pageParam || currentPage;
    setLoading(true);

    const resList = await getPartnersList({
      page,
      perPage: resultsPerPage || 5,
      entityType,
      sortAndFilterData,
    });

    if (resList) {
      setData(resList.data);
      setPaginationData(resList.meta);

      if (resList.meta.current_page > pagesDatas.length) {
        setPagesDatas([
          ...pagesDatas,
          { data: resList.data, meta: resList.meta },
        ]);
      }
    }
    setLoading(false);
  };

  const pageAlreadyLoad = (page: number) => {
    setData(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  const columnHelper = createColumnHelper<IPartnerType>();

  const authUserIsInstaller = useMemo(
    () => ['installateur-admin'].includes(user?.role_name || ''),
    [user]
  );

  useEffect(() => {
    const model = authUserIsInstaller ? 'convention' : null;
    switch (entityType) {
      case 7:
        setRelatedModel('worksite');
        break;
      case 4:
        setRelatedModel('convention');
        break;
      default:
        setRelatedModel(model || 'contract');
        break;
    }
  }, [entityType, user]);

  const columns = useMemo(() => {
    const commonColumns = [
      columnHelper.accessor('company_name', {
        header: () => t('partners.company_name'),
      }),

      columnHelper.accessor('siret', {
        header: () => t('partners.siret'),
      }),
      columnHelper.accessor('address', {
        header: () => t('partners.address'),
      }),
      columnHelper.accessor(
        relatedModel === 'worksite' ? 'worksite_active' : 'contract_active',
        {
          header: () =>
            t(
              relatedModel === 'worksite'
                ? 'partners.worksites'
                : 'partners.contracts'
            ),
          cell: (info) => {
            const badge = getRelatedModelBadge(
              info.getValue(),
              relatedModel === 'worksite'
                ? info.row.original.worksite_done
                : info.row.original.contract_inactive,
              relatedModel
            );
            return (
              <Tag
                color={badge.color}
                label={`${badge.value} ${t(badge.text)}`}
              />
            );
          },
        }
      ),
    ];

    const partnerTypeColumn = [
      columnHelper.accessor('entity_type', {
        header: () => t('partners.partner_type'),
        cell: (info) => {
          const entityTypeLabel =
            info.getValue() && info.getValue() === ENTITY_TYPES.INSTALLATEUR
              ? t('partners.subcontractors.name')
              : globalEnum?.entity_type[info.getValue() as number] || '';

          return <span>{entityTypeLabel}</span>;
        },
      }),
    ];

    return !selectedTab && !isInstaller
      ? [...commonColumns, ...partnerTypeColumn]
      : [...commonColumns];
  }, [data, relatedModel]);

  useEffect(() => {
    getData(1);
    setData([]);
    setPaginationData(undefined);
    setPagesDatas([]);
    setCurrentPage(1);
  }, [entityType, isInstaller]);

  useEffect(() => {
    updateTitleHeader(String(t('partners.installers.title')));
    refreshHeader();
  }, []);

  useEffect(() => {
    if (sortAndFilterData || resultsPerPage) {
      resetPagination(setPaginationData, setPagesDatas);
      getData(1);
      setCurrentPage(1);
    }
  }, [sortAndFilterData, resultsPerPage]);

  useEffect(() => {
    if (selectedTab) {
      // Remove the filters attribute when a tab is selected
      setSortAndFilterData(undefined);
      filterList([], setSortAndFilterData);
    }
  }, [selectedTab]);

  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={
          <TabsLeftPart
            activeButton={selectedTab}
            titlesList={tabs}
            onClick={(title, index) => {
              const filteredPartnersMenu = partnersMenu.filter(
                (elt) => Number(elt.entityType) !== 4
              );
              const tab = filteredPartnersMenu[index - 1];

              if (tab) setSelectedTab(String(tab?.menu_label));

              changeTab(Number(tab?.entityType));
              setPagesDatas([]);
              setCurrentPage(1);
              getData(1);
            }}
          />
        }
        rightPart={
          !authUserIsInstaller || entityType === ENTITY_TYPES.SOUS_TRAITANT ? (
            <>
              <SortAndFilter
                page="PARTENAIRES"
                onSort={(column, direction) =>
                  sortList(column, direction, setSortAndFilterData)
                }
                onFilter={(filters) =>
                  filterList(filters, setSortAndFilterData)
                }
                hideFilter={!!selectedTab || !!isInstaller}
              />
              {entityType === ENTITY_TYPES.INSTALLATEUR && (
                <ButtonOpx
                  onClick={() => setModal(true)}
                  label={t(`partners.installer_invitation.button`)}
                  type="secondary"
                  small
                  addClass="mr-2 px-[1rem] py-[0.625rem]"
                  icon={<SupportContactIcon />}
                />
              )}
              <PartnersNewButton entityType={entityType} setModal={setModal} />
            </>
          ) : undefined
        }
      />
      <div className="flex justify-between">
        <div className="mt-4">
          {paginationData?.total !== undefined && (
            <div>
              {t('pagination.total_results')} : {paginationData.total}
            </div>
          )}
        </div>
        <div className="mb-4">
          <ResultsPerPageButton
            resultsPerPage={resultsPerPage || 5}
            onChange={(value) => {
              setResultsPerPage(value);
              setCurrentPage(1);
              getData(1);
            }}
            options={[5, 10, 20, 50, 100, 250]}
            colorPagination={blueOpx}
          />
        </div>
      </div>
      <ListTable
        loading={loading}
        columns={columns as IColumn[]}
        data={data}
        callBackOnRowClick={(e: IRow) => {
          let link = isInstaller
            ? PARTNERS_ROUTES.INSTALLERS
            : PARTNERS_ROUTES.PARTNERS;

          const rowData = e.original as IPartnerType;

          const hashInstaller =
            user?.entity_type === ENTITY_TYPES.INSTALLATEUR
              ? '#subcontractors'
              : ''; // to determine link when connected as installer or others

          const currentHash =
            rowData.entity_type === ENTITY_TYPES.INSTALLATEUR
              ? hashInstaller
              : `#${PARTNERS_MENU_MAPPING[rowData.entity_type].hash}`;

          link =
            userView?.entity_type === ENTITY_TYPES.INSTALLATEUR &&
            currentHash === '#subcontractors'
              ? `${link}/${userView?.entity_id}`
              : link;

          // // Logique pour déterminer entityType basé sur le hash actuel
          // const newEntityType = Object.keys(PARTNERS_MENU_MAPPING).find(
          //   (key) => PARTNERS_MENU_MAPPING[key].hash === currentHash
          // );
          // TODO: une fois qu'on récupère le entity_type dans les data, remplacer l'utilisation de location.hash par cette donnée.
          navigate(`${link}/${rowData.id_partenaire}${currentHash}`);
          updateDisplayBackButtonHeader(true);
        }}
        rowsPerPage={resultsPerPage}
      />
      <ListPagination
        getData={(page) => {
          setCurrentPage(page);
          if (page > pagesDatas.length) {
            getData(page);
          } else {
            pageAlreadyLoad(page);
          }
        }}
        paginationData={paginationData}
        resultsPerPage={resultsPerPage || 5}
        currentPage={currentPage}
      />
      {modal && entityType === ENTITY_TYPES.SOUS_TRAITANT && (
        <AddSubcontractorForm setModal={setModal} refreshList={getData} />
      )}
      {modal && entityType === ENTITY_TYPES.INSTALLATEUR && (
        <ModalInstallerInvitation setModal={setModal} />
      )}
    </div>
  );
}

export { PartnersList };
