import { blueOpx } from '@assets/color';
import { LoaderCarouselDocument } from '@components/loaders/document/LoaderCarouselDocument';
import { LoaderDocument } from '@components/loaders/document/LoaderDocument';
import { LoaderPagination } from '@components/loaders/LoaderPagination';
import { Pagination } from '@components/atomic/pagination/Pagination';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';

interface IDocumentPreviewProps {
  isLoading: boolean;
  urlDocumentActive: string | null;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  addClassHeight?: string;
  sizingNote?: string;
}

const documentOptions = {
  disableFontFace: true,
  disableRange: true,
  disableStream: true,
  disableAutoFetch: true,
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

function DocumentPreview({
  isLoading,
  urlDocumentActive,
  setIsLoading,
  addClassHeight,
  sizingNote,
}: IDocumentPreviewProps) {
  const container = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [widthToDisplay, setWidthToDisplay] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const onDocumentLoadSuccess = (pageNum: number) => {
    setNumPages(pageNum);
    setPageNumber(1);
    if (setIsLoading) {
      setIsLoading(false);
    }
  };

  const getWidth = () => {
    setWidthToDisplay(containerWidth);
  };

  const resizePdf = () => {
    if (container && container.current) {
      setContainerWidth(container.current.clientWidth);
    }
  };

  useEffect(() => {
    getWidth();
  }, [containerWidth, sizingNote]);

  useEffect(() => {
    resizePdf();
  }, [urlDocumentActive]);

  return (
    <>
      <div className="w-full" ref={container} data-test-id="document">
        {isLoading ? (
          <LoaderDocument addClassHeight={addClassHeight} />
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href={sizingNote !== undefined ? '' : String(urlDocumentActive)}
            target="_blank"
            rel="noreferrer"
          >
            <Document
              file={sizingNote !== undefined ? sizingNote : urlDocumentActive}
              options={documentOptions}
              onLoadSuccess={(pdf) => {
                onDocumentLoadSuccess(pdf.numPages);
              }}
              onLoadError={() => {
                setNumPages(0);
                setPageNumber(1);
              }}
              error={
                <div className="w-full h-full justify-center flex items-center">
                  <div className="text-[.75rem] font-light">
                    {t('global.document_no_display')}
                  </div>
                </div>
              }
              loading={<LoaderDocument addClassHeight={addClassHeight} />}
              className="w-full"
            >
              <Page
                pageNumber={pageNumber}
                className={`overflow-auto scroll-invisible h-[65vh] ${
                  addClassHeight || 'max-h-[65vh]'
                } ${
                  !sizingNote
                    ? 'rounded-default border-borderGrey border-[1px]'
                    : ''
                } `}
                width={widthToDisplay}
                renderAnnotationLayer={false}
              />
            </Document>
          </a>
        )}
      </div>
      <div className="w-full flex items-center max-w-full ">
        {isLoading ? (
          <LoaderCarouselDocument />
        ) : (
          <Document
            file={sizingNote !== undefined ? sizingNote : urlDocumentActive}
            options={documentOptions}
            onLoadSuccess={(pdf) => {
              onDocumentLoadSuccess(pdf.numPages);
            }}
            className="mx-auto flex items-center space-x-[1rem]"
          >
            {numPages > 1 &&
              [...Array(numPages)]
                .map((_, i) => i + 1)
                .map((page) => (
                  <button
                    key={page}
                    type="button"
                    onClick={() => setPageNumber(page)}
                    className={`rounded-default border-[1px] h-[3.5rem] max-h-[3.5rem] w-[3.5rem] max-w-[3.5rem] overflow-hidden ${
                      page === pageNumber
                        ? 'border-blueOpx'
                        : 'border-borderGrey'
                    }`}
                  >
                    <Page
                      pageNumber={page}
                      width={100}
                      height={100}
                      renderAnnotationLayer={false}
                    />
                  </button>
                ))}
          </Document>
        )}
      </div>
      {isLoading ? (
        <LoaderPagination />
      ) : (
        <div>
          {numPages > 1 && (
            <Pagination
              colorPagination={blueOpx}
              numberPage={pageNumber}
              totalNumberPage={numPages}
              onPrevious={() => {
                if (pageNumber > 1) {
                  setPageNumber((prevPageNumber) => prevPageNumber - 1);
                }
              }}
              onNext={() => {
                if (pageNumber < numPages) {
                  setPageNumber((prevPageNumber) => prevPageNumber + 1);
                }
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export { DocumentPreview };

DocumentPreview.defaultProps = {
  setIsLoading: undefined,
  addClassHeight: undefined,
  sizingNote: undefined,
};
