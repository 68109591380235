import { ContractCreation } from '@models/contractCreation/utils/contractCreationContext';
import { IPartnerView } from '@models/partners/utils/types/partnersType';
import { ENTITY_TYPES } from '@utils/roles';
import { toAPIDateStr } from '@utils/format';
import {
  IContractContactType,
  IContractPartnerInfo,
  IContractPaymentCondition,
} from '@models/contractCreation/utils/contractCreationTypes';
import {
  IBeneficiary,
  IBeneficiaryAddress,
} from '@models/beneficiaries/utils/beneficiariesType';
import {
  BeneficiaryTypes,
  ContractTypes,
  SaleProfiles,
} from '@models/contractCreation/utils/enums';

const getPaymentDeadlines = (
  paymentConditions: IContractPaymentCondition[]
) => {
  const filterList = paymentConditions.filter(
    (pay) => pay.delay_days && pay.trigger_percent && pay.trigger_type
  );

  if (filterList.length < 1) return null;
  return filterList.map((pay) => ({ ...pay, status: 1 }));
};

const getPartnerData = (partner: IContractPartnerInfo, isDraft: boolean) => {
  if (partner.id && !isDraft) return null;
  return {
    id: partner.id,
    siren: partner.siret.slice(0, 9),
    siret: partner.siret,
    company_name: partner.company_name,
    zipcode: partner.address.postal_code,
    city: partner.address.city,
    address: partner.address.address,
  };
};

const getAddressData = (address: IBeneficiaryAddress) => {
  return {
    address_type: 1,
    address: address.address,
    postal_code: address.postal_code,
    city: address.city,
    country: address.country,
    elevation: address.elevation,
    latitude: address.latitude,
    longitude: address.longitude,
    additional_address: address.additional_address,
    selected: address.selected,
  };
};

const getContactData = (
  contact: IContractContactType | undefined,
  contactType: 'signataire' | 'contact',
  entityType: number
) => {
  if (!contact) return null;
  return {
    civility: contact.civility,
    firstname: contact.firstname,
    lastname: contact.lastname,
    function: contact.function,
    phone_number: contact.phone_number,
    email: contact.email,
    contact_type: contactType === 'signataire' ? 2 : 1,
    entity_type: entityType,
  };
};

const getBeneficiaryContact = (
  contact: IBeneficiary | undefined,
  contactType: 'signataire' | 'contact'
) => {
  if (!contact) return null;
  return {
    civility: contact.civility || 1,
    firstname: contact.firstname,
    lastname: contact.lastname,
    phone_number: contact.phone_number || '',
    email: contact.email,
    contact_type: contactType === 'signataire' ? 2 : 1,
    entity_type: ENTITY_TYPES.BENEFICIAIRE,
    function: contact.function || '',
  };
};

const getReferentsData = (
  referent: { id: number; contact_user_id: number } | undefined,
  user: IPartnerView,
  type: 'signataire' | 'contact_1' | 'contact_2'
) => {
  if (!referent) return null;
  const contactType = type === 'signataire' ? 2 : 3;
  return {
    id: referent.id,
    contact_user_id: referent.contact_user_id,
    contact_type: contactType,
    entity_type: user.entity_type,
  };
};

const numVolume = (value: string, unity: 'mega' | 'giga') => {
  const num = Number(value.replace(',', '.').replace(' ', ''));

  return unity === 'giga' ? num * 1000000 : num * 1000;
};

const tripartitePayload = (
  state: ContractCreation,
  user: IPartnerView,
  copyId: number | null,
  isDraft = false
) => {
  const {
    amo,
    intermediaryBusiness,
    entityTo,
    referents,
    worksiteAddresses,
    operationPrices,
    paymentConditions,
  } = state;

  const isB2C = state.beneficiaryType === BeneficiaryTypes.PERSON;

  return {
    copyId,
    last_step: state.lastStep,
    contract_type: state.contractType,
    amo: {
      entity_to_id: amo.id,
      new_entity: getPartnerData(amo, isDraft),
      contract_type: state.contractType,
      entity_type: ENTITY_TYPES.AMO,
      signatory_id: amo.signatory_id,
      contact_signataire: getContactData(
        amo.signataire,
        'signataire',
        ENTITY_TYPES.AMO
      ),
      contact_1: getContactData(amo.contact, 'contact', ENTITY_TYPES.AMO),
    },
    intermediary_business:
      intermediaryBusiness.siret === ''
        ? null
        : {
            entity_to_id: intermediaryBusiness.id,
            new_entity: getPartnerData(intermediaryBusiness, isDraft),
            contract_type: state.contractType,
            entity_type: ENTITY_TYPES.APPORTEUR_AFFAIRES,
            contact_signataire: getContactData(
              intermediaryBusiness.signataire,
              'signataire',
              ENTITY_TYPES.APPORTEUR_AFFAIRES
            ),
            contact_1: getContactData(
              intermediaryBusiness.signataire,
              'contact',
              ENTITY_TYPES.APPORTEUR_AFFAIRES
            ),
            signatory_id: intermediaryBusiness.signatory_id,
            minimum_bonus_allocation: state.commission,
          },
    beneficiary_with_contacts: {
      beneficiary: {
        civility: state.beneficiary.civility || 1,
        id: state.beneficiary.id || entityTo.id,
        company_name: isB2C ? undefined : entityTo.company_name,
        siret: isB2C ? undefined : entityTo.siret,
        siren: isB2C ? undefined : entityTo.siret.slice(0, 9),
        firstname: isB2C ? state.beneficiary.firstname : undefined,
        lastname: isB2C ? state.beneficiary.lastname : undefined,
        phone_number: isB2C ? state.beneficiary.phone_number : undefined,
        email: isB2C ? state.beneficiary.email : undefined,
        address:
          entityTo.siret === ''
            ? getAddressData(state.beneficiary.address)
            : getAddressData(entityTo.address),
        entity_type: ENTITY_TYPES.BENEFICIAIRE,
      },
      contact_signataire: isB2C
        ? getBeneficiaryContact(state.beneficiary.contact, 'signataire')
        : getContactData(
            entityTo.signataire,
            'signataire',
            ENTITY_TYPES.BENEFICIAIRE
          ),
      contact_1: isB2C
        ? getBeneficiaryContact(state.beneficiary.contact, 'contact')
        : getContactData(
            entityTo.contact,
            'contact',
            ENTITY_TYPES.BENEFICIAIRE
          ),
    },
    internal_reference: state.referencePerso,
    reference: state.referenceOpx,
    start_date: toAPIDateStr(state.startDate),
    end_date: toAPIDateStr(state.endDate),
    contract_id: state.relatedContract?.id,
    rai_id: state.rai.value < 1 ? null : state.rai.value,
    beneficiary_type: state.beneficiaryType,
    internal: {
      entity_to_id: user.entity_id,
      contract_type: state.contractType,
      entity_type: user.entity_type,
      contact_signataire: getReferentsData(
        referents?.contact_signataire,
        user,
        'signataire'
      ),
      contact_1: getReferentsData(referents?.contact_1, user, 'contact_1'),
      contact_2: getReferentsData(referents?.contact_2, user, 'contact_2'),
    },
    worksite_adresses: worksiteAddresses.map((wa) => ({
      ...wa,
      address_type: 2,
    })),
    incentive_type: state.incentiveType,
    convention_operations: operationPrices,
    payment_deadlines: getPaymentDeadlines(paymentConditions),
    rai_mention: state.raiMention,
    can_use_custom_prices: state.canUseCustomPrices,
  };
};

const conventionPayload = (
  state: ContractCreation,
  user: IPartnerView,
  copyId: number | null,
  isDraft = false
) => {
  const { intermediaryBusiness, entityTo, referents, isElectronicSignature } =
    state;
  return {
    copyId,
    last_step: state.lastStep,
    contract_type: state.contractType,
    is_electronic_signature: isElectronicSignature,
    to: {
      entity_to_id: entityTo.id,
      new_entity: getPartnerData(state.entityTo, isDraft),
      contract_type: state.contractType,
      entity_type: ENTITY_TYPES.INSTALLATEUR,
      signatory_id: entityTo.signatory_id,
      contact_signataire: getContactData(
        entityTo.signataire,
        'signataire',
        ENTITY_TYPES.INSTALLATEUR
      ),
      contact_1: getContactData(
        entityTo.contact,
        'contact',
        ENTITY_TYPES.INSTALLATEUR
      ),
      minimum_bonus_allocation: state.commission,
    },
    intermediary_business:
      intermediaryBusiness.siret === ''
        ? null
        : {
            entity_to_id: intermediaryBusiness.id,
            new_entity: getPartnerData(intermediaryBusiness, isDraft),
            contract_type: state.contractType,
            entity_type: ENTITY_TYPES.APPORTEUR_AFFAIRES,
            contact_signataire: getContactData(
              intermediaryBusiness.signataire,
              'signataire',
              ENTITY_TYPES.APPORTEUR_AFFAIRES
            ),
            contact_1: getContactData(
              intermediaryBusiness.signataire,
              'contact',
              ENTITY_TYPES.APPORTEUR_AFFAIRES
            ),
            minimum_bonus_allocation: state.commission,
            signatory_id: intermediaryBusiness.signatory_id,
          },
    internal_reference: state.referencePerso,
    reference: state.referenceOpx,
    start_date: toAPIDateStr(state.startDate),
    end_date: toAPIDateStr(state.endDate),
    beneficiary_type: state.beneficiaryType,
    contract_id: state.relatedContract?.id,
    rai_id: state.rai.value < 1 ? null : state.rai.value,
    volume_precaire_kwhc: numVolume(state.volumeMax.precaire, 'giga'),
    volume_classique_kwhc: numVolume(state.volumeMax.classic, 'giga'),
    minimum_volume_precaire_kwhc: numVolume(state.volumeMin.precaire, 'giga'),
    minimum_volume_classique_kwhc: numVolume(state.volumeMin.classic, 'giga'),
    offer_validity_delay: state.validationDelay,
    internal: {
      entity_to_id: user.entity_id,
      new_entity: null,
      contract_type: state.contractType,
      entity_type: user.entity_type,
      contact_signataire: getReferentsData(
        referents?.contact_signataire,
        user,
        'signataire'
      ),
      contact_1: getReferentsData(referents?.contact_1, user, 'contact_1'),
      contact_2: getReferentsData(referents?.contact_2, user, 'contact_2'),
    },
    worksite_adresses: state.worksiteAddresses,
    incentive_type: state.incentiveType,
    convention_operations: state.operationPrices,
    payment_deadlines: getPaymentDeadlines(state.paymentConditions),
    rai_mention: state.raiMention,
    can_use_custom_prices: state.canUseCustomPrices,
  };
};

const modelPayload = (
  state: ContractCreation,
  user: IPartnerView,
  copyId: number | null,
  isDraft = false
) => {
  const { intermediaryBusiness, referents, isElectronicSignature } = state;

  return {
    copyId,
    last_step: state.lastStep,
    contract_type: state.contractType,
    is_electronic_signature: isElectronicSignature,
    intermediary_business:
      intermediaryBusiness.siret === ''
        ? null
        : {
            entity_to_id: intermediaryBusiness.id,
            new_entity: getPartnerData(intermediaryBusiness, isDraft),
            contract_type: state.contractType,
            entity_type: ENTITY_TYPES.APPORTEUR_AFFAIRES,
            contact_signataire: getContactData(
              intermediaryBusiness.signataire,
              'signataire',
              ENTITY_TYPES.APPORTEUR_AFFAIRES
            ),
            contact_1: getContactData(
              intermediaryBusiness.signataire,
              'contact',
              ENTITY_TYPES.APPORTEUR_AFFAIRES
            ),
            minimum_bonus_allocation: state.commission,
            signatory_id: intermediaryBusiness.signatory_id,
          },
    internal_reference: state.referencePerso,
    reference: state.referenceOpx,
    start_date: toAPIDateStr(state.startDate),
    end_date: toAPIDateStr(state.endDate),
    beneficiary_type: state.beneficiaryType,
    contract_id: state.relatedContract?.id,
    rai_id: state.rai.value < 1 ? null : state.rai.value,
    volume_precaire_kwhc: numVolume(state.volumeMax.precaire, 'giga'),
    volume_classique_kwhc: numVolume(state.volumeMax.classic, 'giga'),
    minimum_volume_precaire_kwhc: numVolume(state.volumeMin.precaire, 'giga'),
    minimum_volume_classique_kwhc: numVolume(state.volumeMin.classic, 'giga'),
    offer_validity_delay: state.validationDelay,
    internal: {
      entity_to_id: user.entity_id,
      new_entity: null,
      contract_type: state.contractType,
      entity_type: user.entity_type,
      contact_signataire: getReferentsData(
        referents?.contact_signataire,
        user,
        'signataire'
      ),
      contact_1: getReferentsData(referents?.contact_1, user, 'contact_1'),
      contact_2: getReferentsData(referents?.contact_2, user, 'contact_2'),
    },
    worksite_adresses: state.worksiteAddresses,
    convention_operations: state.operationPrices,
    payment_deadlines: getPaymentDeadlines(state.paymentConditions),
    rai_mention: state.raiMention,
    can_manage_worksite_documents: state.canManageWorksiteDocuments,
    incentive_type: state.incentiveType,
    can_use_custom_prices: state.canUseCustomPrices,
  };
};

const salePayload = (
  state: ContractCreation,
  user: IPartnerView,
  isDraft = false
) => {
  const { entityTo, referents } = state;
  return {
    last_step: state.lastStep,
    contract_type: state.contractType,
    incentive_type: null,
    to: {
      entity_to_id: entityTo.id,
      new_entity: getPartnerData(entityTo, isDraft),
      contract_type: state.contractType,
      entity_type: state.partnerType,
      signatory_id: entityTo.signatory_id,
      contact_signataire: getContactData(
        entityTo.signataire,
        'signataire',
        state.partnerType
      ),
      contact_1: getContactData(entityTo.contact, 'contact', state.partnerType),
      acteur_type:
        state.saleProfile === SaleProfiles.BUYER
          ? SaleProfiles.SELLER
          : SaleProfiles.BUYER,
    },
    intermediary_business: null,
    internal_reference: state.referencePerso,
    reference: state.referenceOpx,
    start_date: toAPIDateStr(state.startDate),
    end_date: toAPIDateStr(state.endDate),
    origination: state.origination,
    pu_precaire: numVolume(state.salePrices.precaire, 'mega'),
    pu_classique: numVolume(state.salePrices.classic, 'mega'),
    volume_precaire_kwhc: numVolume(state.volumeMax.precaire, 'mega'),
    volume_classique_kwhc: numVolume(state.volumeMax.classic, 'mega'),
    amount_penalty_precaire: numVolume(state.penalties.precaire, 'mega'),
    amount_penalty_classique: numVolume(state.penalties.classic, 'mega'),
    internal: {
      acteur_type: state.saleProfile,
      entity_to_id: user.entity_id,
      new_entity: null,
      contract_type: state.contractType,
      entity_type: user.entity_type,
      contact_signataire: getReferentsData(
        referents?.contact_signataire,
        user,
        'signataire'
      ),
      contact_1: getReferentsData(referents?.contact_1, user, 'contact_1'),
      contact_2: getReferentsData(referents?.contact_2, user, 'contact_2'),
    },
    rai_mention: state.raiMention,
  };
};

const mandantPayload = (
  state: ContractCreation,
  user: IPartnerView,
  editId: number | null,
  isDraft = false
) => {
  const { entityTo, intermediaryBusiness, referents } = state;
  return {
    editId,
    last_step: state.lastStep,
    contract_type: state.contractType,
    incentive_type: state.incentiveType,
    to: {
      entity_to_id: entityTo.id,
      new_entity: getPartnerData(entityTo, isDraft),
      contract_type: state.contractType,
      signatory_id: entityTo.signatory_id,
      entity_type: ENTITY_TYPES.MANDATAIRE,
      contact_signataire: getContactData(
        entityTo.signataire,
        'signataire',
        ENTITY_TYPES.MANDATAIRE
      ),
      contact_1: getContactData(
        entityTo.contact,
        'contact',
        ENTITY_TYPES.MANDATAIRE
      ),
    },
    intermediary_business:
      intermediaryBusiness.siret === ''
        ? null
        : {
            entity_to_id: intermediaryBusiness.id,
            new_entity: getPartnerData(intermediaryBusiness, isDraft),
            contract_type: state.contractType,
            entity_type: ENTITY_TYPES.APPORTEUR_AFFAIRES,
            contact_signataire: getContactData(
              intermediaryBusiness.signataire,
              'signataire',
              ENTITY_TYPES.APPORTEUR_AFFAIRES
            ),
            contact_1: getContactData(
              intermediaryBusiness.signataire,
              'contact',
              ENTITY_TYPES.APPORTEUR_AFFAIRES
            ),
            minimum_bonus_allocation: state.commission,
          },
    lot_minimum_volume_kwhc:
      state.contractType === ContractTypes.MANDAT
        ? null
        : numVolume(state.deliveryVolume, 'mega'),
    internal_reference: state.referencePerso,
    reference: state.referenceOpx,
    start_date: toAPIDateStr(state.startDate),
    end_date: toAPIDateStr(state.endDate),
    minimum_volume_precaire_kwhc: numVolume(state.volumeMin.precaire, 'giga'),
    minimum_volume_classique_kwhc: numVolume(state.volumeMin.classic, 'giga'),
    volume_precaire_kwhc: numVolume(state.volumeMax.precaire, 'giga'),
    volume_classique_kwhc: numVolume(state.volumeMax.classic, 'giga'),
    amount_penalty_precaire: numVolume(state.penalties.precaire, 'mega'),
    amount_penalty_classique: numVolume(state.penalties.classic, 'mega'),
    internal: {
      entity_to_id: user.entity_id,
      new_entity: null,
      contract_type: state.contractType,
      entity_type: user.entity_type,
      contact_signataire: getReferentsData(
        referents?.contact_signataire,
        user,
        'signataire'
      ),
      contact_1: getReferentsData(referents?.contact_1, user, 'contact_1'),
      contact_2: getReferentsData(referents?.contact_2, user, 'contact_2'),
    },
    contract_operations: state.operationPrices,
    payment_deadlines: getPaymentDeadlines(state.paymentConditions),
    rai_mention: state.raiMention,
    can_manage_worksite_documents: state.canManageWorksiteDocuments,
  };
};

export const getPayload = (
  state: ContractCreation,
  user: IPartnerView,
  copyId: number | null,
  isDraft = false
) => {
  if (user) {
    switch (state.contractType) {
      case ContractTypes.CONVENTION:
        return conventionPayload(state, user, copyId, isDraft);
      case ContractTypes.TRIPARTITE:
        return tripartitePayload(state, user, copyId, isDraft);
      case ContractTypes.MANDAT:
      case ContractTypes.MANDAT_NON_DEPOSANT:
        return mandantPayload(state, user, copyId, isDraft);
      default:
        return salePayload(state, user, isDraft);
    }
  }

  return null;
};

export const getModelPayload = (
  state: ContractCreation,
  user: IPartnerView,
  copyId: number | null,
  isDraft = false
) => {
  if (user) return modelPayload(state, user, copyId, isDraft);

  return null;
};
