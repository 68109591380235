/* eslint-disable no-nested-ternary */
import { textGrey } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { useTranslation } from 'react-i18next';
import TagIncentiveType from '@models/worksites/components/TagIncentiveType';
import { IKeyStringType } from '../../../../types/globalTypes';

type IWorksitesCardGeneralInfosProps = {
  infos: IKeyStringType;
  isCompact?: boolean;
  adminMode?: boolean;
};

function WorksitesCardGeneralInfos({
  infos,
  isCompact,
  adminMode,
}: IWorksitesCardGeneralInfosProps): JSX.Element {
  const { t } = useTranslation();

  const renderDiv = (isTitle: boolean, title: string, subtitle: string) => {
    return (
      <div className="flex flex-col">
        <div className="flex justify-between flex-wrap">
          <div
            className={
              isTitle ? 'flex items-center space-x-[1rem]' : 'flex flex-col'
            }
          >
            <span
              className={`text-left ${
                isTitle ? 'font-[500] text-[1.25rem]' : ''
              }`}
              data-test-id="infos_title"
            >
              {`${
                !isTitle && isCompact
                  ? `${t('forms.address.placeholder')} : `
                  : ''
              }${title.charAt(0).toUpperCase() + title.slice(1)}`}
            </span>
            {isTitle && (
              <Tag
                label={infos.ref}
                color={textGrey}
                dataTestId="reference_tag"
              />
            )}
          </div>
          <div
            data-test-id="infos_subtitle"
            className={`${
              isCompact ? 'mt-2' : isTitle ? 'mt-2 ml-auto' : 'ml-auto'
            }`}
          >
            <div className="text-end">
              {`${subtitle}`}
              {!isTitle && (
                <div className="mt-2">
                  <TagIncentiveType
                    incentiveType={Number(infos.incentive_type)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-[1rem] w-full sm:w-1/3 flex-col space-y-[.5rem] text-[.875rem] h-max">
      {adminMode && (
        <div className="flex items-center space-x-[1rem]">{infos.entity}</div>
      )}
      {renderDiv(true, infos.name, infos.contract)}
      {renderDiv(false, infos.address, `RAI : ${infos.rai}`)}
    </div>
  );
}

WorksitesCardGeneralInfos.defaultProps = {
  isCompact: false,
  adminMode: false,
};

export { WorksitesCardGeneralInfos };
