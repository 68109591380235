import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { blueOpx } from '@assets/color';
import { useTranslation } from 'react-i18next';
import { numFormatSpace } from '@utils/functions';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import { useContext } from 'react';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { OperationTypeEnum } from '@utils/enums';
import { getActivityLabel } from '@models/worksiteCreation/utils/functions';

interface IOneOperationInfosProps {
  title: string;
  subtitle: string;
  border: boolean;
  isTotalCost?: boolean;
  operation: IOperation;
  iconOrNumber: number | JSX.Element;
}

function OneOperationInfos({
  title,
  subtitle,
  border,
  isTotalCost,
  operation,
  iconOrNumber,
}: IOneOperationInfosProps) {
  const { t } = useTranslation();
  const totalCostTitle = `${t(
    'worksite_creation.simulation.summary.total_cost'
  )}`;
  const gisementTitle = `${t(
    'worksite_creation.global_renovation.cee_calculation'
  )}`;

  const { worksiteOperationType, simulatorData } = useContext(
    WorksiteCreationContext
  );

  const {
    gisementCee = 0,
    gisementCdp = 0,
    cee = 0,
    cdp = 0,
    mpr = 0,
  } = operation.primes || {};
  const haveCdp = cdp > 0;
  const gisement = (gisementCdp > 0 ? gisementCdp : gisementCee) / 1000;
  const ceeValue = cdp > cee ? cdp : cee;

  const withActivity = () => {
    if (operation && operation.code) {
      return (
        operation.code.toLowerCase().includes('bat-') &&
        simulatorData['general.activity'] &&
        simulatorData['general.activity'] !== ''
      );
    }
    return null;
  };

  return (
    <div
      className={`${border ? 'border-b border-b-borderGrey' : ''} p-[1.5rem]`}
    >
      <div className={`${isTotalCost ? 'grid grid-cols-2 gap-x-4' : 'w-full'}`}>
        <InfosWithIcon
          colorIcon={blueOpx}
          icon={iconOrNumber}
          infos={[{ title, subtitle }]}
        />
        {ceeValue > 0 && (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: t('worksite_creation.simulation.summary.prime_cee'),
                subtitle: `${numFormatSpace(ceeValue)} €`,
              },
            ]}
          />
        )}
      </div>

      {isTotalCost &&
        worksiteOperationType !== OperationTypeEnum.GLOBAL_RENOVATION && (
          <>
            <div
              className={`mt-[1rem] ${
                gisement > 0 ? 'grid grid-cols-2 gap-x-4' : 'w-full'
              }`}
            >
              {operation.totalCost && operation.totalCost !== 999999 && (
                <InfosWithIcon
                  spaceLeft
                  infos={[
                    {
                      title: totalCostTitle,
                      subtitle:
                        `${numFormatSpace(operation.totalCost || 0)} €` || '',
                    },
                  ]}
                />
              )}

              {gisement > 0 && (
                <InfosWithIcon
                  spaceLeft
                  infos={[
                    {
                      title: gisementTitle,
                      subtitle: `${numFormatSpace(gisement)} MWhc`,
                    },
                  ]}
                />
              )}
              {withActivity() && (
                <InfosWithIcon
                  spaceLeft
                  infos={[
                    {
                      title: t('worksite_creation.simulation.activity_sector'),
                      subtitle: getActivityLabel(
                        simulatorData['general.activity'],
                        t
                      ),
                    },
                  ]}
                />
              )}
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4">
              {isTotalCost && (
                <InfosWithIcon
                  spaceLeft
                  infos={[{ title: 'FOST', subtitle: operation.code }]}
                />
              )}
              {haveCdp && (
                <InfosWithIcon
                  spaceLeft
                  infos={[
                    {
                      title: t(
                        'worksite_creation.simulation.summary.bonus_type'
                      ),
                      subtitle: 'Coup de pouce',
                    },
                  ]}
                />
              )}
              {mpr > 0 && (
                <InfosWithIcon
                  spaceLeft
                  infos={[
                    {
                      title: t('worksites.ma_prime_renov'),
                      subtitle: `${numFormatSpace(mpr)} €`,
                    },
                  ]}
                />
              )}
            </div>
          </>
        )}
    </div>
  );
}

export { OneOperationInfos };

OneOperationInfos.defaultProps = {
  isTotalCost: false,
};
